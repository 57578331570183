import React, { useCallback, useEffect, useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import SwitchButton from 'shared/components/andtComponents/Switch';
import Button from 'shared/components/andtComponents/Button';
import Spinner from 'shared/components/andtComponents/Spinner';
import RowLikeAccordion from 'shared/components/RowLikeAccordion';
import TableLikeHeader from 'shared/components/TableLikeHeader.tsx';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import classNames from 'classnames';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { AlertTypeOnUpdate, AlertTypesEnum, CommitmentExpAlert } from '../types.ts';
import styles from '../alerts.module.scss';
import UpdateCommitmentModal from './UpdateCommitmentModal.tsx';
import t from '../texts.ts';
import { useAlerts } from '../hooks/useAlerts.ts';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal.tsx';
import FormCommitmentExpiration from './FormCommitmentExpiration.tsx';
import EditDeleteButtons from '../components/EditDeleteButtons.tsx';
import { useUsersSettings, riServerPropName, spServerPropName } from '../hooks/useUsersSettings.ts';
import { isDefaultExpanded } from '../ServiceCostTab/constants.ts';
import EmptyAlerts from '../components/EmptyState';
import { Recipient } from 'shared/components/RecipientsSelect';

const PlusIconComponent = () => <GenerateIcon iconName={ICONS.plus.name} />;

const numberToAlertType = [
  'changeCostService',
  'commitementExpirationRi',
  'commitementExpirationSP',
] as AlertTypeOnUpdate[];

export interface CommitmentExpirationTabProps {
  spExpirationAlerts: CommitmentExpAlert[];
  riExpirationAlerts?: CommitmentExpAlert[];

  refetchAlerts(): void;
}

const CommitmentExpirationTab: React.FC<CommitmentExpirationTabProps> = ({
  riExpirationAlerts,
  spExpirationAlerts,
  refetchAlerts,
}) => {
  const { usersStore } = useRootStore();
  const [isAllExpanded, setIsAllExpanded] = useState(isDefaultExpanded);
  const [isRIExpanded, setIsRIExpanded] = useState(isDefaultExpanded);
  const [isSPExpanded, setIsSPExpanded] = useState(isDefaultExpanded);
  const [showUpdateModal, setShowUpdateModal] = useState<null | AlertTypesEnum>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState<CommitmentExpAlert | null>(null);
  const { updateAlerts, deleteAlert, checkSPPlansExist } = useAlerts();
  const { mutate: handleUpdateAlert } = updateAlerts(refetchAlerts);
  const { refetch: handleDelete } = deleteAlert(activeAlert?.uuid || '');
  const { updateUsersSettings, fetchUsersSettings, setUsersSettingsLocally } = useUsersSettings();
  const { refetch: refetchSettings, isLoading, data: usersSettings } = fetchUsersSettings();
  const { mutate: handleUpdateSettings } = updateUsersSettings({ onSuccess: () => refetchSettings() });
  const {
    data: isSpPlansExist = false,
    isLoading: isSpPlansLoading,
    refetch: refetchIsSpPlansExist,
  } = checkSPPlansExist();
  const onCloseDeleteAlert = useCallback(() => setShowDeleteModal(false), []);
  const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
  const canAddAlertsEmpty = isAzure && isSpPlansExist && !isSpPlansLoading;

  useEffect(() => {
    if (isAzure) {
      refetchIsSpPlansExist();
    }
  }, [isAzure]);

  useEffect(() => {
    if (spExpirationAlerts?.length === 0) {
      setIsSPExpanded(false);
    }
    if (riExpirationAlerts?.length === 0) {
      setIsRIExpanded(false);
    }
  }, [spExpirationAlerts, riExpirationAlerts]);

  const onUpdate = useCallback(
    (
      daysBeforeExpiry: number,
      recipientsEmails: string,
      recipients: Recipient[],
      alertType: AlertTypesEnum,
      uuid?: string,
    ) => {
      const defaultTypeToCreate = AlertTypesEnum.RI_EXPIRATION;
      handleUpdateAlert([
        {
          uuid,
          daysBeforeExpiry,
          recipientsEmails,
          recipients,
          alertStatus: 'save',
          alertType: numberToAlertType[activeAlert?.alertType || alertType || defaultTypeToCreate],
        },
      ]);
      setShowUpdateModal(null);
    },
    [handleUpdateAlert, activeAlert],
  );

  const onCreateClick = useCallback(
    (newAlertType: AlertTypesEnum) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      setActiveAlert(null);
      setShowUpdateModal(newAlertType);
    },
    [],
  );

  const onEditClick = useCallback((alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowUpdateModal(alert.alertType);
  }, []);

  const onDeleteClick = useCallback((alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowDeleteModal(true);
  }, []);

  const onDeleteAlert = useCallback(() => {
    handleDelete();
    setTimeout(refetchAlerts, 1000);
    setShowDeleteModal(false);
  }, []);

  const onAllExpanded = useCallback(() => {
    setIsAllExpanded(!isAllExpanded);
    setIsRIExpanded(!isAllExpanded);
    setIsSPExpanded(!isAllExpanded);
  }, [isAllExpanded]);

  const onSwitchAlerts = useCallback(
    (alertType: AlertTypesEnum) => async (checked) => {
      setUsersSettingsLocally(
        alertType === AlertTypesEnum.RI_EXPIRATION ? riServerPropName : spServerPropName,
        checked ? 1 : 0,
      );
      handleUpdateSettings({ alertType, checked });
    },
    [],
  );

  const isRIActive = !!usersSettings?.[riServerPropName];
  const isSPActive = !!usersSettings?.[spServerPropName];
  const showEmptyState = !isSPActive && (isAzure || !isRIActive);

  return (
    <div>
      <TableLikeHeader
        onExpandClick={onAllExpanded}
        title={t('ALERT_RULES')}
        isExpanded={isAllExpanded}
        totalNumber={(riExpirationAlerts?.length || 0) + (spExpirationAlerts.length || 0)}
        className={styles.marginBottom32}
      >
        <span className={styles.alertSwitcherLabel}>{`${t('SAVING_PLAN')}s(${spExpirationAlerts.length})`}</span>
        <Tooltip title={t('ENABLE_ALL_SAVING_PLANS')} arrow placement="top" className={styles.tooltipFlex}>
          {' '}
          <SwitchButton
            isChecked={isSPActive}
            onChange={onSwitchAlerts(AlertTypesEnum.SP_EXPIRATION)}
            className="styles.switch"
          />
        </Tooltip>
        {riExpirationAlerts && (
          <>
            <span className={styles.alertSwitcherLabel}>
              {`${t('RESERVED_INSTANCES')}(${riExpirationAlerts?.length})`}
            </span>
            <Tooltip title={t('ENABLE_ALL_RESERVED_INSTANCE')} arrow placement="top" className={styles.tooltipFlex}>
              {' '}
              <SwitchButton
                isChecked={isRIActive}
                onChange={onSwitchAlerts(AlertTypesEnum.RI_EXPIRATION)}
                className="styles.switch"
              />
            </Tooltip>
          </>
        )}
      </TableLikeHeader>
      {isLoading && <Spinner />}
      {showEmptyState && <EmptyAlerts canAdd={canAddAlertsEmpty} onAdd={onCreateClick(AlertTypesEnum.SP_EXPIRATION)} />}
      {isSPActive && (
        <RowLikeAccordion
          isExternalExpand={isSPExpanded && spExpirationAlerts.length > 0}
          isExpandDisabled={!spExpirationAlerts.length}
          headerContent={t('SAVING_PLANS_EXPIRATION')}
          onExpand={(e, expanded) => setIsSPExpanded(expanded)}
          actionsBlock={
            <Button
              automationId="create-alert-button"
              isTextButton
              text={t('CREATE_ALERT_RULE')}
              onClick={onCreateClick(AlertTypesEnum.SP_EXPIRATION)}
              icon={PlusIconComponent}
            />
          }
        >
          {spExpirationAlerts.map((alert) => (
            <div className={styles.alertsRow} automation-id={`${alert.uuid}alert-container`}>
              <FormCommitmentExpiration
                className={classNames(styles.commitmentForm, styles.formDisabled)}
                daysBeforeExpiry={alert.daysBeforeExpiry}
                recipientsEmails={alert.recipientsEmails}
                recipients={alert.recipients}
                isSavingPlan
              />
              <EditDeleteButtons
                style={{ marginTop: 13 }}
                onEdit={() => onEditClick(alert)}
                onDelete={() => onDeleteClick(alert)}
                alertId={alert.uuid}
              />
            </div>
          ))}
        </RowLikeAccordion>
      )}
      {isRIActive && riExpirationAlerts && (
        <RowLikeAccordion
          isExternalExpand={isRIExpanded && riExpirationAlerts.length > 0}
          isExpandDisabled={!riExpirationAlerts.length}
          headerContent={t('RESERVATIONS')}
          onExpand={(e, expanded) => setIsRIExpanded(expanded)}
          actionsBlock={
            <Button
              isTextButton
              text={t('CREATE_ALERT_RULE')}
              onClick={onCreateClick(AlertTypesEnum.RI_EXPIRATION)}
              icon={PlusIconComponent}
              automationId="create-alert-rule"
            />
          }
        >
          {riExpirationAlerts.map((alert) => (
            <div className={styles.alertsRow} automation-id={`${alert.uuid}alert-container`}>
              <FormCommitmentExpiration
                className={classNames(styles.commitmentForm, styles.formDisabled)}
                daysBeforeExpiry={alert.daysBeforeExpiry}
                recipientsEmails={alert.recipientsEmails}
                recipients={alert.recipients}
              />
              <EditDeleteButtons
                style={{ marginTop: 13 }}
                onEdit={() => onEditClick(alert)}
                onDelete={() => onDeleteClick(alert)}
                alertId={alert.uuid}
              />
            </div>
          ))}
        </RowLikeAccordion>
      )}
      {showUpdateModal && (
        <UpdateCommitmentModal
          isEdit={Boolean(activeAlert)}
          alertType={showUpdateModal}
          onUpdate={onUpdate}
          onClose={() => setShowUpdateModal(null)}
          daysBeforeExpiry={activeAlert?.daysBeforeExpiry}
          recipientsEmails={activeAlert?.recipientsEmails}
          recipients={activeAlert?.recipients}
          uuid={activeAlert?.uuid}
        />
      )}
      {showDeleteModal && <DeleteConfirmationModal onClose={onCloseDeleteAlert} onDelete={onDeleteAlert} />}
    </div>
  );
};

export default CommitmentExpirationTab;
