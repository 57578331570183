import React, { useCallback, useContext, useState } from 'react';
import styles from './K8sOnboarding.module.scss';
import AddClusterModal from './AddCluster';
import { AddClusterFields } from './config.ts';
import Button from 'shared/components/andtComponents/Button';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { cloudTypeToSimpleIcon } from 'shared/constants/appConstants';
import { ReactComponent as ClusterIcon } from 'shared/img/icons/k8sCluster.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { OnboardingContext } from './OnBoardingContext.tsx';
import { AddedCluster } from './types.ts';

type Props = {
  className?: string;
};

const ClusterInformationStep: React.FC<Props> = () => {
  const { addedClusters, updateCluster, deleteCluster } = useContext(OnboardingContext);
  const [showClusterModal, setShowClusterModal] = useState(false);
  const [activeCluster, setActiveCluster] = useState<AddedCluster | null>(null);
  const {
    usersStore: { currDispUserAccountKey, currDispUserCloudAccountType, getCurrentDisplayedAccountName },
  } = useRootStore();
  const accountName = getCurrentDisplayedAccountName(currDispUserAccountKey);
  const CloudTypeIcon = cloudTypeToSimpleIcon[currDispUserCloudAccountType];

  const onEditCluster = (clusterToEdit: null | AddedCluster) => {
    setActiveCluster(clusterToEdit || null);
    setShowClusterModal(true);
  };

  const onClose = useCallback(() => {
    setActiveCluster(null);
    setShowClusterModal(false);
  }, []);

  return (
    <div className={styles.clusterInfoContainer}>
      <div className={styles.stepTitle}>
        <CloudTypeIcon />
        {accountName}
      </div>
      <div className={styles.addButton}>
        <Button text={'+ Add Cluster'} onClick={() => onEditCluster(null)} isTextButton />
      </div>
      {addedClusters.map((cluster, index) => (
        <div className={styles.clusterRow} key={index}>
          <div className={styles.clusterTile} key={index}>
            <ClusterIcon />
            {cluster[AddClusterFields.clusterName]}
          </div>
          <div className={styles.clusterActions}>
            <Button
              isTextButton
              className={'me-5'}
              automation-id={`cluster-delete-button`}
              icon={() => <GenerateIcon iconName={ICONS.deleteRegular.name} />}
              onClick={() => {
                deleteCluster(cluster.id as string);
              }}
            />
            <Button
              isTextButton
              className={styles.actionIcon}
              automation-id={`cluster-edit-button`}
              icon={() => <GenerateIcon iconName={ICONS.edit.name} />}
              onClick={() => {
                onEditCluster(cluster);
              }}
            />
          </div>
        </div>
      ))}
      {showClusterModal && (
        <AddClusterModal onClose={onClose} activeCluster={activeCluster} updateCluster={updateCluster} />
      )}
    </div>
  );
};

export default ClusterInformationStep;
