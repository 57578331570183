import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { useRootStore } from '../../../app/contexts/RootStoreContext';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const fetchRealmData = async (username, realmId) => {
  try {
    const realm = username.toLowerCase().includes(realmId)
      ? null
      : await API.get(
          'billings',
          `/api/v1/user-management/users/user-realm?username=${encodeURIComponent(username.toLowerCase())}`,
        );
    return realm;
  } catch {
    return null;
  }
};

const fetchPermissions = async () => {
  try {
    return await API.get('billings', `/api/v1/user-management/users/permissions`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

export const useUpdateUserRole = (mutationSettings = {}) =>
  useMutation({
    mutationFn: ({ userKey, newRoleId }) =>
      API.put('billings', '/api/v1/users/update-user-role', { body: { users: [{ userKey, newRoleId }] } }),
    ...mutationSettings,
  });

export const useUsers = () => {
  const brand = useBrand();
  const queryClient = useQueryClient();
  const { appStore, usersStore } = useRootStore();
  return {
    fetchRealm: (username) =>
      queryClient.fetchQuery({
        queryKey: [apiConstants.QUERY_KEYS.REALM],
        queryFn: async () => fetchRealmData(username, brand.realm.excludeDomain),
      }),
    fetchPermissions: () =>
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.PERMISSIONS],
        queryFn: fetchPermissions,
        enabled: !!usersStore.currentDisplayedUserKey && appStore.isKeyCloakManagement,
        staleTime: Infinity,
      }),
  };
};
