import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { getSearchParamsPanel } from 'app/containers/MainDashboard/utils.js';
import NavigateIcon from 'app/containers/MainDashboard/components/NavigateIcon.jsx';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import { ExternalLinks } from 'shared/enums/external-links.enum';
import SaveDashboardPanel from 'app/containers/MainDashboard/components/SaveDashboardPanel.jsx';
import mainStyles from '../../mainDashboardCommon.module.scss';
import styles from './forecastHeader.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const ForecastHeader = ({
  params,
  title,
  forecastMonths,
  forecastNumMonth,
  setForecastNumMonth,
  panelType,
  hideForecastDetails = false,
  hideSaveDashboard = false,
}) => {
  const brand = useBrand();
  const { appStore } = useRootStore();

  return (
    <div className={mainStyles.headerRow}>
      <span className={mainStyles.header}>{title}</span>
      <div className={styles.headerWrapper}>
        {!hideForecastDetails && (
          <>
            <div className={styles.forecast}>
              <GenerateIcon iconName={ICONS.sparkles.name} className={styles.icon} />
              <span className={styles.title}>Forecast</span>
              <InfoPopover
                mode="outline"
                className={{
                  icon: styles.infoIcon,
                  tooltip: tooltipStyles.whiteTooltip,
                  arrow: tooltipStyles.whiteArrow,
                }}
              >
                <span className={styles.tooltip}>
                  <a href={ExternalLinks.ForecastArchitecture} className={styles.navigateText}>
                    Learn more
                  </a>{' '}
                  about {brand.name}&apos;s forecasting
                </span>
              </InfoPopover>
            </div>
            <div className={styles.monthsWrapper}>
              {forecastMonths?.map((month) => (
                <Button
                  key={month.value}
                  text={month.label}
                  className={{ button: classNames(styles.month, forecastNumMonth === month.value && styles.selected) }}
                  onClick={() => setForecastNumMonth(month.value)}
                />
              ))}
            </div>
          </>
        )}
        <NavigateIcon
          getSearchParams={() => getSearchParamsPanel(params, appStore.isPpApplied)}
          id={PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST}
          tooltipText="Open in Cost & Usage Explorer page (without Forecast)"
        />
        {!hideSaveDashboard && <SaveDashboardPanel panelType={panelType} />}
      </div>
    </div>
  );
};

ForecastHeader.propTypes = {
  params: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  forecastMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
  forecastNumMonth: PropTypes.string.isRequired,
  setForecastNumMonth: PropTypes.func.isRequired,
  hideForecastDetails: PropTypes.bool,
  hideSaveDashboard: PropTypes.bool,
  panelType: PropTypes.string,
};

export default ForecastHeader;
