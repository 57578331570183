import React, { createContext, useState, useCallback } from 'react';
import { AddClusterFields } from './config.ts';
import { AddedCluster } from "./types.ts";
import { v4 } from 'uuid';

export const OnboardingContext = createContext({
  addedClusters: [] as AddedCluster[],
  updateCluster: (() => {}) as (updatingCluster: AddedCluster, activeCluster?: AddedCluster | null) => void,
  deleteCluster: (() => {}) as (id: string) => void,
});

export const OnboardingContextProvider = ({ children }) => {
  const [addedClusters, setClusters] = useState<AddedCluster[]>([]);

  const deleteCluster = useCallback(
    (clusterId: string) => {
      setClusters((s) => [...s].filter((c) => c.id !== clusterId))
    }, []);

  const updateCluster = useCallback((updatingCluster: AddedCluster, activeCluster?: AddedCluster | null) => {
    updatingCluster.id = activeCluster?.id || v4();
    setClusters((s) =>
      activeCluster?.id
        ? s.map((cluster) => (cluster.id === activeCluster?.id ? updatingCluster : cluster))
        : [...s, updatingCluster],
    );
  }, []);

  return <OnboardingContext.Provider value={{ addedClusters, updateCluster, deleteCluster }}>{children}</OnboardingContext.Provider>;
};
export default OnboardingContextProvider;
