import { ExternalLinks } from 'shared/enums/external-links.enum';
import config from './config';

export const generateTopicConfiguration = ({ bucketRegionName }) => `{
    "TopicConfigurations": [
        {
            "TopicArn": "arn:aws:sns:${bucketRegionName}:932213950603:${config.connect.TOPIC}",
            "Events": [
                "s3:ObjectCreated:*"
            ]
        }
    ]
}
`;

export const generateRole = ({ externalId }) => `{
    "Version": "2012-10-17",
    "Statement": {
      "Effect": "Allow",
      "Principal": { "AWS": "arn:aws:iam::932213950603:root" },
      "Action": "sts:AssumeRole",
      "Condition": {
        "StringEquals": {
          "sts:ExternalId": "${externalId}"
        }
      }
    }
}
`;

export const generatePolicy = ({ invoiceBucketName }) => {
  // When generating this policy on the on-boarding phase we need to generate it with the S3
  // read/write permissions for the billing report bucket so we could copy it to our bucket
  // when the user is not on-boarding this policy is generated without them.
  const isRootAccountOnBoarding = invoiceBucketName
    ? `{
            "Effect": "Allow",
            "Action": [
                "s3:ListBucket",
                "s3:Get*"
            ],
            "Resource": [
                "arn:aws:s3:::${invoiceBucketName}/*",
                "arn:aws:s3:::${invoiceBucketName}"
            ]
        },
        {
            "Effect": "Allow",
            "Action": [
                "s3:Put*"
            ],
            "Resource": [
                "arn:aws:s3:::${config.connect.PILEUS_BUCKET}/*",
                "arn:aws:s3:::${config.connect.PILEUS_BUCKET}"
            ]
        },
      `
    : '';
  return `{
    "Version": "2012-10-17",
    "Statement": [
        ${isRootAccountOnBoarding}
        {
            "Effect": "Allow",
            "Action": ["ec2:Describe*"],
            "Resource": "*"
        },
        {
            "Action": [
                "organizations:ListAccounts"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "elasticloadbalancing:Describe*"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "s3:ListBucket",
                "s3:GetBucketLocation",
                "s3:ListBucketVersions",
                "s3:GetBucketVersioning",
                "s3:GetLifecycleConfiguration",
                "s3:GetEncryptionConfiguration",
                "s3:ListAllMyBuckets",
                "s3:ListBucketMultipartUploads",
                "s3:ListMultipartUploadParts"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "cloudwatch:ListMetrics",
                "cloudwatch:GetMetricStatistics",
                "cloudwatch:GetMetricData",
                "logs:DescribeLogGroups",
                "logs:GetQueryResults"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "logs:CreateExportTask",
                "logs:StartQuery"
            ],
            "Resource": [
                "arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance",
                "arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance:*",
                "arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance:*:*"
            ],
            "Effect": "Allow"
        },
        {
            "Action": [
                "autoscaling:Describe*"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "eks:ListFargateProfiles",
                "eks:DescribeNodegroup",
                "eks:ListNodegroups",
                "eks:DescribeFargateProfile",
                "eks:ListTagsForResource",
                "eks:ListUpdates",
                "eks:DescribeUpdate",
                "eks:DescribeCluster",
                "eks:ListClusters"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "dynamodb:Describe*",
                "dynamodb:List*",
                "tag:GetResources",
                "rds:DescribeDBInstances",
                "rds:DescribeDBClusters",
                "rds:ListTagsForResource",
                "ecs:DescribeClusters",
                "redshift:DescribeClusters",
                "es:ListDomainNames",
                "es:DescribeElasticsearchDomains",
                "elasticache:DescribeCacheClusters",
                "kinesis:ListStreams",
                "kinesis:DescribeStream",
                "kms:ListKeys",
                "kms:DescribeKey",
                "kms:ListResourceTags",${isRootAccountOnBoarding
      ? ''
      : `
                "kms:ListKeyRotations",`
    }
                "es:DescribeReservedInstances",
                "es:DescribeReservedElasticsearchInstances",
                "rds:DescribeReservedDBInstances",
                "elasticache:DescribeReservedCacheNodes",
                "redshift:DescribeReservedNodes",
                "savingsplans:DescribeSavingsPlans",
                "cloudTrail:DescribeTrails"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "account:GetAccountInformation",
                "billing:GetBillingData",
                "billing:GetBillingDetails",
                "billing:GetBillingNotifications",
                "billing:GetBillingPreferences",
                "billing:GetContractInformation",
                "billing:GetCredits",
                "billing:GetIAMAccessPreference",
                "billing:GetSellerOfRecord",
                "billing:ListBillingViews",
                "ce:DescribeNotificationSubscription",
                "ce:DescribeReport",
                "ce:GetAnomalies",
                "ce:GetAnomalyMonitors",
                "ce:GetAnomalySubscriptions",
                "ce:GetCostAndUsage",
                "ce:GetCostAndUsageWithResources",
                "ce:GetCostCategories",
                "ce:GetCostForecast",
                "ce:GetDimensionValues",
                "ce:GetPreferences",
                "ce:GetReservationCoverage",
                "ce:GetReservationPurchaseRecommendation",
                "ce:GetReservationUtilization",
                "ce:GetRightsizingRecommendation",
                "ce:GetSavingsPlansCoverage",
                "ce:GetSavingsPlansPurchaseRecommendation",
                "ce:GetSavingsPlansUtilization",
                "ce:GetSavingsPlansUtilizationDetails",
                "ce:GetTags",
                "ce:GetUsageForecast",
                "ce:ListCostAllocationTags",
                "ce:ListSavingsPlansPurchaseRecommendationGeneration",
                "consolidatedbilling:GetAccountBillingRole",
                "consolidatedbilling:ListLinkedAccounts",
                "cur:DescribeReportDefinitions",
                "cur:GetClassicReport",
                "cur:GetClassicReportPreferences",
                "cur:GetUsageReport",
                "cur:ValidateReportDestination",
                "freetier:GetFreeTierAlertPreference",
                "freetier:GetFreeTierUsage",
                "invoicing:GetInvoiceEmailDeliveryPreferences",
                "invoicing:GetInvoicePDF",
                "invoicing:ListInvoiceSummaries",
                "payments:GetPaymentInstrument",
                "payments:GetPaymentStatus",
                "payments:ListPaymentPreferences",
                "tax:GetTaxInheritance",
                "tax:GetTaxRegistrationDocument",
                "tax:ListTaxRegistrations"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "logs:DescribeExportTasks"
            ],
            "Resource": "*",
            "Effect": "Allow"
        },
        {
            "Action": [
                "s3:Put*"
            ],
            "Resource": "arn:aws:s3:::${config.env}-k8s-cloudwatch-logs-*",
            "Effect": "Allow"
        }
    ]
}
  `;
};

export const connectToFileDataGenerator = ({ invoiceBucketName, arnRoleNum, awsAccountID }) => `[DEFAULT]
    aws_account_id = ${awsAccountID}
    s3_invoice_bucket = ${invoiceBucketName}
    pileus_role_arn = ${arnRoleNum}
    `;

export const generateOnboardingScript = ({ externalId, autoSetupLinkedAccounts, bucketName }) =>
  `
#!/bin/bash -e

# Constants
REGION="us-east-1"
STACK_NAME="Anodot-Onboarding"
TEMPLATE_FILE="AnodotPayer.yaml"
TEMPLATE_FILE_LINKED="AnodotLinkedRecursive.yaml"

ROLE_NAME="PileusRole"
LAMBDA_NAME="OnboardingAnodotNotificationLambda"

# Parameters
ExternalId="${externalId}"
CreateBucket="true"
OnboardLinkedAccounts="${autoSetupLinkedAccounts ? 'true' : 'false'}"
CURbucketName="${bucketName}"

# Colors
GREEN="\\033[0;32m"
YELLOW="\\033[0;33m"
RED="\\033[0;31m"
CYAN="\\033[0;36m"
NC="\\033[0m"

# Functions

# Validate AWS Access
validate_aws_access() {
  echo -e "\${CYAN}Validating AWS access credentials...\${NC}"
  if ! aws sts get-caller-identity > /dev/null 2>&1; then
    echo -e "\${RED}Error: AWS credentials are invalid or not configured properly.\${NC}"
    echo -e "\${YELLOW}Reference:\${NC}"
    echo -e "\${CYAN}To set up AWS CLI, run the following commands:\${NC}"
    echo -e "\${GREEN}  aws configure\${NC}"
    echo -e "\${CYAN}You will be prompted to enter your AWS Access Key, Secret Access Key, Region, and output format.\${NC}"
    echo -e "\${CYAN}For more details, visit: ${ExternalLinks.AwsConfiguringWettingsForCLI}\${NC}"
    exit 1
  fi
  echo -e "\${GREEN}AWS access validation successful.\${NC}"
}

# Validate if the current account is the Payer (Management) Account
validate_payer_account() {
  echo -e "\${CYAN}Validating if the current account is a Payer (Management) account...\${NC}"

  # Get the current account ID
  export CURRENT_ACCOUNT_ID=$(aws sts get-caller-identity --query 'Account' --output text)

  # Fetch account details from AWS Organizations
  ACCOUNT_INFO=$(aws organizations describe-account --account-id "$CURRENT_ACCOUNT_ID" 2>/dev/null)

  # Check if account is Management
  if echo "$ACCOUNT_INFO" | grep -q '"Status": "ACTIVE"'; then
    echo -e "\${GREEN}Account ID: \${CURRENT_ACCOUNT_ID} is validated as active.\${NC}"
  else
    echo -e "\${RED}Failed to validate the current account. Ensure it is part of AWS Organizations.\${NC}"
    exit 1
  fi

  # Check if the account is a Management (Payer) account
  MGMT_ACCOUNT_ID=$(aws organizations describe-organization --query 'Organization.MasterAccountId' --output text)
  if [[ "$CURRENT_ACCOUNT_ID" == "$MGMT_ACCOUNT_ID" ]]; then
    echo -e "\${GREEN}Current account is the Payer (Management) account.\${NC}"
  else
    echo -e "\${RED}Error: The current account (\${CURRENT_ACCOUNT_ID}) is not a Payer (Management) account.\${NC}"
    exit 1
  fi
}

# Validate CloudFormation template
validate_template() {
  echo -e "\${CYAN}Validating CloudFormation template: \${TEMPLATE_FILE}\${NC}"

  if aws cloudformation validate-template --region "$REGION" --template-body "file://$TEMPLATE_FILE" >/dev/null 2>&1; then
    echo -e "\${GREEN}Template validation successful.\${NC}"
  else
    echo -e "\${RED}Template validation failed. Exiting.\${NC}"
    exit 1
  fi
}

# Validate S3 bucket name
validate_bucket_name() {
  echo -e "\${CYAN}Validating S3 bucket name: \${CURbucketName}\${NC}"

  if [[ -z $CURbucketName ]]; then
    export CURbucketName="cur-\${CURRENT_ACCOUNT_ID}"
  fi

  if [[ \${#CURbucketName} -lt 3 || \${#CURbucketName} -gt 63 ]]; then
    echo -e "\${RED}Bucket name must be between 3 and 63 characters long. Exiting.\${NC}"
    exit 1
  fi

  if ! [[ "$CURbucketName" =~ ^[a-z0-9.-]+$ ]]; then
    echo -e "\${RED}Bucket name can only contain lowercase letters, numbers, periods, and hyphens. Exiting.\${NC}"
    exit 1
  fi

  if [[ "$CURbucketName" =~ ^[.-] || "$CURbucketName" =~ [.-]$ ]]; then
    echo -e "\${RED}Bucket name cannot start or end with a period or hyphen. Exiting.\${NC}"
    exit 1
  fi

  if [[ "$CURbucketName" =~ \\.\\. ]]; then
    echo -e "\${RED}Bucket name cannot contain consecutive periods. Exiting.\${NC}"
    exit 1
  fi

  echo -e "\${GREEN}Bucket name is valid.\${NC}"
}

# Check if S3 bucket already exists
check_bucket_exists() {
  echo -e "\${CYAN}Checking if S3 bucket \${CURbucketName} already exists...\${NC}"
  if aws s3api head-bucket --bucket "$CURbucketName" 2>/dev/null; then
    if [ $CreateBucket == "true" ]; then
      echo -e "\${YELLOW}S3 bucket \${CURbucketName} already exists. Please delete the bucket or update the parameter value.\${NC}"
      exit 1
    else
      echo -e "\${YELLOW}S3 bucket \${CURbucketName} already exists.\${NC}"
      return
    fi
  fi
  echo -e "\${GREEN}S3 bucket \${CURbucketName} does not exist. Proceeding...\${NC}"
}

# Check if IAM role already exists
check_role_exists() {
  echo -e "\${CYAN}Checking if IAM role \${ROLE_NAME} already exists...\${NC}"
  if aws iam get-role --role-name "$ROLE_NAME" 2>/dev/null; then
    echo -e "\${YELLOW}IAM role \${ROLE_NAME} already exists. Please delete the role or update the template.\${NC}"
    exit 1
  fi
  echo -e "\${GREEN}IAM role \${ROLE_NAME} does not exist. Proceeding...\${NC}"
}

# Check if Lambda function already exists
check_lambda_exists() {
  echo -e "\${CYAN}Checking if Lambda function \${LAMBDA_NAME} already exists...\${NC}"
  if aws lambda get-function --function-name "$LAMBDA_NAME" 2>/dev/null; then
    echo -e "\${YELLOW}Lambda function \${LAMBDA_NAME} already exists. Please delete the function or update the template.\${NC}"
    exit 1
  fi
  echo -e "\${GREEN}Lambda function \${LAMBDA_NAME} does not exist. Proceeding...\${NC}"
}

# Deploy CloudFormation stack
deploy_stack() {
  echo -e "\${CYAN}Deploying CloudFormation stack: \${STACK_NAME}\${NC}"

  local parameters=(
    ParameterKey=ExternalId,ParameterValue="$ExternalId"
    ParameterKey=CostAndUsageReport,ParameterValue="true"
    ParameterKey=CURbucket,ParameterValue="$CreateBucket"
    ParameterKey=CURbucketName,ParameterValue="$CURbucketName"
  )

  aws cloudformation create-stack --region "$REGION" --stack-name "$STACK_NAME" \\
    --template-body "file://$TEMPLATE_FILE" \\
    --parameters "\${parameters[@]}" \\
    --capabilities CAPABILITY_NAMED_IAM > /dev/null

  echo -e "\${CYAN}Stack creation initiated. Waiting for completion...\${NC}"
  aws cloudformation wait stack-create-complete --region "$REGION" --stack-name "$STACK_NAME"
  echo -e "\${GREEN}Stack creation completed successfully.\${NC}"
}


# Deploy CloudFormation stack for linked accounts
deploy_stack_linked() {
  echo -e "\${CYAN}Deploying CloudFormation stack: \${STACK_NAME}-linked-accounts\${NC}"
  aws cloudformation create-stack --region "$REGION" --stack-name "\${STACK_NAME}-linked-accounts" \\
    --template-body "file://$TEMPLATE_FILE_LINKED" \\
    --parameters ParameterKey=ExternalId,ParameterValue="$ExternalId" \\
    --capabilities CAPABILITY_NAMED_IAM > /dev/null

  echo -e "\${CYAN}Stack creation initiated. Waiting for completion...\${NC}"
  aws cloudformation wait stack-create-complete --region "$REGION" --stack-name "\${STACK_NAME}-linked-accounts"
  echo -e "\${GREEN}Stack creation completed successfully.\${NC}"
}

# Fetch CUR bucket name dynamically
fetch_cur_bucket_name() {
  if [ -z "$CURbucketName" ]; then
    CURbucketName=$(aws sts get-caller-identity --query 'Account' --output text)
    CURbucketName="cur-\${CURbucketName}" # Default bucket name based on account ID
  fi
  export CURbucketName="$CURbucketName"
  echo -e "\${CYAN}Using CUR bucket name: \${CURbucketName}\${NC}"
}


# Check if StackSets trusted access is enabled
StackSetsTrustAccess() {
  echo -e "\${CYAN}Checking if StackSets trusted access is enabled...\${NC}"
  SERVICE_PRINCIPAL="member.org.stacksets.cloudformation.amazonaws.com"

  ENABLED_SERVICES=$(aws organizations list-aws-service-access-for-organization --query 'EnabledServicePrincipals[].ServicePrincipal' --output text)

  if echo "$ENABLED_SERVICES" | grep -q "$SERVICE_PRINCIPAL"; then
    echo -e "\${GREEN}Trusted access for CloudFormation StackSets is already enabled.\${NC}"
    return 0
  else
    echo -e "\${RED}Error: Trusted access for CloudFormation StackSets is not enabled.\${NC}"
    echo -e "\${YELLOW}Please enable it manually in the AWS Management Console or using the CLI.\${NC}"
    echo -e "\${CYAN}For more details: ${ExternalLinks.AwsActivateTrustedAccessForStackSetsWithOrganizations}\${NC}"
    return 1
  fi
}

# Check if a stack already exists
check_stack_exists() {
  echo -e "\${CYAN}Checking if stack $1 already exists...\${NC}"
  STACK_STATUS=$(aws cloudformation describe-stacks --region "$REGION" --stack-name "$1" 2>/dev/null | jq -r '.Stacks[0].StackStatus')

  if [[ -n "$STACK_STATUS" ]]; then
    echo -e "\${YELLOW}Stack $1 already exists with status: \${STACK_STATUS}.\${NC}"
    if [[ "$STACK_STATUS" == "CREATE_COMPLETE" || "$STACK_STATUS" == "UPDATE_COMPLETE" ]]; then
      echo -e "\${GREEN}No need to redeploy. Skipping stack deployment.\${NC}"
      return 0
    else
      echo -e "\${RED}Stack $1 is in status: \${STACK_STATUS}. Please resolve this issue before proceeding.\${NC}"
      exit 1
    fi
  fi

  echo -e "\${GREEN}Stack $1 does not exist. Proceeding with deployment...\${NC}"
  return 1
}

# Main Execution
main() {
  echo -e "\${CYAN}Starting Anodot onboarding deployment...\${NC}"

  echo "-------------------------------"
  # Validate AWS Access
  validate_aws_access

  echo "-------------------------------"
  # Validate Payer account
  validate_payer_account

  if ! (check_stack_exists $STACK_NAME); then
    echo "-------------------------------"
    # Validate CloudFormation template
    validate_template

    echo "-------------------------------"
    # Validate S3 bucket name
    validate_bucket_name

    echo "-------------------------------"
    # Fetch CUR bucket name dynamically
    fetch_cur_bucket_name

    echo "-------------------------------"
    # Check if S3 bucket exists
    check_bucket_exists

    echo "-------------------------------"
    # Check if IAM role exists
    check_role_exists

    echo "-------------------------------"
    # Check if Lambda function exists
    check_lambda_exists

    echo "-------------------------------"
    # Deploy the CloudFormation stack for payer account
          deploy_stack
  fi

  echo "-------------------------------"
  # Deploy the CloudFormation stack for linked accounts
  if [[ "$OnboardLinkedAccounts" == "true" ]]; then
    if ! (check_stack_exists "\${STACK_NAME}-linked-accounts"); then
      if StackSetsTrustAccess; then deploy_stack_linked; fi
    fi
  fi

  echo -e "\${GREEN}Anodot onboarding deployment completed successfully!\${NC}"
}

# Execute the main function
main
`;

export const generateOnboardingScriptHelper = () => `
AWSTemplateFormatVersion: '2010-09-09'
Outputs:
  AnodotRoleArn:
    Description: Anodot Role ARN
    Value: !GetAtt 'AnodotRole.Arn'
Parameters:
  ExternalId:
    Description: Insert the External ID you got from Anodot
    Type: String

  CostAndUsageReport:
    Type: String
    Default: 'true'
    Description: 'Deploy CUR'
    AllowedValues:
    - 'true'
    - 'false'

  CURbucket:
    Type: String
    Default: 'true'
    Description: 'Deploy S3 Bucket for CUR'
    AllowedValues:
    - 'true'
    - 'false'

  CURbucketName:
    Type: String
    Default: "empty"
    Description: 'Name of the S3 bucket for CUR'

Conditions:

  CondBucket:  !Equals
    - !Ref 'CURbucket'
    - true

  CondCUR:  !Equals
    - !Ref 'CostAndUsageReport'
    - true
Resources:
  S3AnodotBucket:
    Type: AWS::S3::Bucket
    Condition: CondBucket
    DeletionPolicy: Retain
    Properties:
      BucketName: !Ref 'CURbucketName'
      NotificationConfiguration:
        TopicConfigurations:
          - Topic: 'arn:aws:sns:us-east-1:932213950603:${config.env}-new-invoice-upload'
            Event: 's3:ObjectCreated:*'
  S3AnodotBucketPolicy:
    Type: AWS::S3::BucketPolicy
    Condition: CondBucket
    DependsOn: S3AnodotBucket
    Properties:
      Bucket: !Ref 'CURbucketName'
      PolicyDocument:
        Version: '2012-10-17'
        Statement:
        - 
          Sid: 'Stmt1335892150622dd'
          Action:
            - "s3:GetBucketAcl"
            - "s3:GetBucketPolicy"
          Effect: "Allow"
          Resource: !Sub "\${S3AnodotBucket.Arn}"
          Principal:
            Service: "billingreports.amazonaws.com"
        - 
          Sid: "Stmt133589dffsdf0622dd"
          Action: 
            - "s3:PutObject"
          Effect: "Allow"
          Resource: !Sub "\${S3AnodotBucket.Arn}/*"
          Principal:
            Service: "billingreports.amazonaws.com"

  AnodotManagedPolicy:
    Properties:
      Description: Anodot Account Policy
      PolicyDocument:
        Statement:
          - Effect: Allow
            Action:
              - s3:ListBucket
              - s3:Get*
            Resource:
            - !Sub "\${S3AnodotBucket.Arn}/*"
            - !Sub "\${S3AnodotBucket.Arn}"
          - Effect: Allow
            Action:
              - s3:Put*
            Resource:
              - arn:aws:s3:::${config.env}-invoice-update/*
              - arn:aws:s3:::${config.env}-invoice-update
          - Effect: Allow
            Action:
              - ec2:Describe*
            Resource: "*"
          - Effect: Allow
            Action:
              - organizations:ListAccounts
            Resource: "*"
          - Effect: Allow
            Action:
              - elasticloadbalancing:Describe*
            Resource: "*"
          - Effect: Allow
            Action:
              - s3:ListBucket
              - s3:GetBucketLocation
              - s3:ListBucketVersions
              - s3:GetBucketVersioning
              - s3:GetLifecycleConfiguration
              - s3:GetEncryptionConfiguration
              - s3:ListAllMyBuckets
              - s3:ListBucketMultipartUploads
              - s3:ListMultipartUploadParts
            Resource: "*"
          - Effect: Allow
            Action:
              - cloudwatch:ListMetrics
              - cloudwatch:GetMetricStatistics
              - cloudwatch:GetMetricData
              - logs:DescribeLogGroups
              - logs:GetQueryResults
            Resource: "*"
          - Effect: Allow
            Action:
              - logs:CreateExportTask
              - logs:StartQuery
            Resource:
              - arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance
              - arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance:*
              - arn:aws:logs:*:*:log-group:/aws/containerinsights/*/performance:*:*
          - Effect: Allow
            Action:
              - autoscaling:Describe*
            Resource: "*"
          - Effect: Allow
            Action:
              - eks:ListFargateProfiles
              - eks:DescribeNodegroup
              - eks:ListNodegroups
              - eks:DescribeFargateProfile
              - eks:ListTagsForResource
              - eks:ListUpdates
              - eks:DescribeUpdate
              - eks:DescribeCluster
              - eks:ListClusters
            Resource: "*"
          - Effect: Allow
            Action:
              - dynamodb:Describe*
              - dynamodb:List*
              - tag:GetResources
              - rds:DescribeDBInstances
              - rds:DescribeDBClusters
              - rds:ListTagsForResource
              - ecs:DescribeClusters
              - redshift:DescribeClusters
              - es:ListDomainNames
              - es:DescribeElasticsearchDomains
              - elasticache:DescribeCacheClusters
              - kinesis:ListStreams
              - kinesis:DescribeStream
              - kms:ListKeys
              - kms:DescribeKey
              - kms:ListResourceTags
              - cloudTrail:DescribeTrails
            Resource: "*"
          - Effect: Allow
            Action:
              #- aws-portal:ViewBilling < Deprecated
              - account:GetAccountInformation
              - billing:GetBillingData
              - billing:GetBillingDetails
              - billing:GetBillingNotifications
              - billing:GetBillingPreferences
              - billing:GetContractInformation
              - billing:GetCredits
              - billing:GetIAMAccessPreference
              - billing:GetSellerOfRecord
              - billing:ListBillingViews
              - ce:DescribeNotificationSubscription
              - ce:DescribeReport
              - ce:GetAnomalies
              - ce:GetAnomalyMonitors
              - ce:GetAnomalySubscriptions
              - ce:GetCostAndUsage
              - ce:GetCostAndUsageWithResources
              - ce:GetCostCategories
              - ce:GetCostForecast
              - ce:GetDimensionValues
              - ce:GetPreferences
              - ce:GetReservationCoverage
              - ce:GetReservationPurchaseRecommendation
              - ce:GetReservationUtilization
              - ce:GetRightsizingRecommendation
              - ce:GetSavingsPlansPurchaseRecommendation
              - ce:GetSavingsPlansUtilization
              - ce:GetSavingsPlansUtilizationDetails
              - ce:GetSavingsPlansCoverage
              - ce:GetTags
              - ce:GetUsageForecast
              - ce:ListCostAllocationTags
              - ce:ListSavingsPlansPurchaseRecommendationGeneration
              - consolidatedbilling:GetAccountBillingRole
              - consolidatedbilling:ListLinkedAccounts
              - cur:GetClassicReport
              - cur:DescribeReportDefinitions
              - cur:GetUsageReport
              - cur:GetClassicReportPreferences
              - cur:ValidateReportDestination
              - freetier:GetFreeTierAlertPreference
              - freetier:GetFreeTierUsage
              - invoicing:GetInvoiceEmailDeliveryPreferences
              - invoicing:GetInvoicePDF
              - invoicing:ListInvoiceSummaries
              - payments:GetPaymentInstrument
              - payments:GetPaymentStatus
              - payments:ListPaymentPreferences
              - tax:GetTaxInheritance
              - tax:GetTaxRegistrationDocument
              - tax:ListTaxRegistrations
            Resource: "*"
          - Effect: Allow
            Action:
              - logs:DescribeExportTasks
            Resource: "*"
          - Effect: Allow
            Action:
              - s3:Put*
            Resource: arn:aws:s3:::${config.env}-k8s-cloudwatch-logs-*
        Version: '2012-10-17'
    Type: AWS::IAM::ManagedPolicy
  AnodotRole:
    Properties:
      RoleName: "PileusRole"
      AssumeRolePolicyDocument:
        Statement:
          - Action: sts:AssumeRole
            Condition:
              StringEquals:
                sts:ExternalId: !Ref 'ExternalId'
            Effect: Allow
            Principal:
              AWS: arn:aws:iam::932213950603:root
        Version: '2012-10-17'
      ManagedPolicyArns:
        - !Ref 'AnodotManagedPolicy'
    Type: AWS::IAM::Role

  AnodotNotificationLambda:
    Type: AWS::Lambda::Function
    DependsOn: S3AnodotBucket
    Properties:
      Environment:
        Variables:
          ACC_ID: !Ref 'AWS::AccountId'
          FunctionName: OnboardingAnodotNotificationLambda
          CUR_BUCKET_NAME: !Ref 'CURbucketName'
      Code:
        ZipFile: |
          import boto3
          from pprint import pprint
          import pathlib
          import os
          import cfnresponse

          def main(event, context):
              
          ########create file########
              ACC_ID = os.environ.get('ACC_ID')
              CUR_BUCKET_NAME = os.environ.get('CUR_BUCKET_NAME')
              file_path = ('/tmp/'+ACC_ID+'_connect_to_pileus.txt')
              file = open(file_path, 'w')
              file.write('[DEFAULT]\\n')
              file.write('    aws_account_id = '+ACC_ID+'\\n')
              file.write('    s3_invoice_bucket = '+CUR_BUCKET_NAME+'\\n')
              file.write('    pileus_role_arn = arn:aws:iam::'+ACC_ID+':role/PileusRole\\n    ')
              file.close()
          ########upload file########
              s3 = boto3.client("s3")
              bucket_name = CUR_BUCKET_NAME
              object_name = ACC_ID+"_connect_to_pileus.txt"
              file_name = os.path.join('/tmp', ACC_ID+'_connect_to_pileus.txt')
              response = s3.upload_file(file_name, bucket_name, object_name)
              pprint(response)  # prints None
          ########return status to cloudformation stack########
              result = cfnresponse.SUCCESS
              cfnresponse.send(event, context, result, {})
              delete_lambda_function()
          def delete_lambda_function():
            function_name = os.environ['FunctionName']
            client = boto3.client('lambda')
            client.delete_function(FunctionName=function_name)

      FunctionName: OnboardingAnodotNotificationLambda
      Handler: index.main
      Role: !GetAtt
        - OnboardingAnodotNotificationLambdaRole
        - Arn
      Runtime: python3.9
      Timeout: 120
  OnboardingAnodotNotificationLambdaRole:
    Type: AWS::IAM::Role
    Properties:
      RoleName: OnboardingAnodotNotificationLambdaRole
      AssumeRolePolicyDocument:
        Version: "2012-10-17"
        Statement:
        - Effect: Allow
          Principal:
            Service:
              - lambda.amazonaws.com
          Action:
            - sts:AssumeRole
      Path: /
      Policies:
        - PolicyName: OnboardingAnodotNotificationLambdaPolicy
          PolicyDocument:
            Version: "2012-10-17"
            Statement:
              - Sid: "BasicExecution"
                Effect: Allow
                Action: 
                  - 'logs:CreateLogGroup'
                Resource: !Join [':',['arn:aws:logs',!Ref 'AWS::Region', !Ref 'AWS::AccountId','*']]
              - Sid: "PutLogEvents"
                Effect: Allow
                Action: 
                  - 'logs:CreateLogStream'
                  - 'logs:PutLogEvents'  
                Resource: !Join ['',['arn:aws:logs:','*:',!Ref 'AWS::AccountId',':log-group:/aws/lambda/', 'AnodotNotificationLambdaUpdate', ':*' ]]
              - Sid: "AnodotNotificationLambda"
                Effect: Allow
                Action: 
                  - s3:PutObject
                  - s3:GetObject
                Resource: !Join ['',['arn:aws:s3:::',!Ref 'CURbucketName','/*']]
              - Sid: "DeleteLambda"
                Effect: Allow
                Action: 
                  - 'lambda:DeleteFunction'
                Resource: !Join ['',['arn:aws:lambda:','us-east-1:',!Ref 'AWS::AccountId',':function',':OnboardingAnodotNotificationLambda']]


  RunNotificationLambda:
    Type: Custom::LambdaRun
    DependsOn: AnodotNotificationLambda
    Properties:
      ServiceToken: !GetAtt AnodotNotificationLambda.Arn

  CostUsageReportDefinitionAnodot:
    Type: AWS::CUR::ReportDefinition
    Condition: CondCUR
    DependsOn: RunNotificationLambda
    Properties:
      AdditionalSchemaElements:
        - "RESOURCES"
      Compression: "GZIP"
      Format: "textORcsv"
      ReportName: !Ref 'CURbucketName'
      ReportVersioning: "CREATE_NEW_REPORT"
      RefreshClosedReports: True
      S3Bucket: !Ref 'S3AnodotBucket'
      S3Prefix: "hourly"
      S3Region: "us-east-1"
      TimeUnit: "HOURLY"
`;

export const generateLinkedAccountsScriptHelper = () => `
AWSTemplateFormatVersion: '2010-09-09'

Parameters:
  ExternalId:
    Description: Insert the External ID you got from Anodot
    Type: String
  StackSetName:
    Description: Name of the StackSet to manage
    Type: String
    Default: "AnodotLinkedAccount"

Resources:

  GetRootIdLambda:
    Type: AWS::Lambda::Function
    Properties:
      Code:
        ZipFile: |
          import boto3
          import cfnresponse

          def lambda_handler(event, context):
              client = boto3.client('organizations')
              try:
                  response = client.list_roots()
                  root_id = response['Roots'][0]['Id']
                  responseData = {
                      'RootId': root_id
                  }
                  cfnresponse.send(event, context, cfnresponse.SUCCESS, responseData)
              except Exception as e:
                  print(e)
                  cfnresponse.send(event, context, cfnresponse.FAILED, {})
      Handler: index.lambda_handler
      Role: !GetAtt LambdaExecutionRole.Arn
      Runtime: python3.11
      Timeout: 30

  LambdaExecutionRole:
    Type: AWS::IAM::Role
    Properties:
      AssumeRolePolicyDocument:
        Version: "2012-10-17"
        Statement:
          - Effect: Allow
            Principal:
              Service: lambda.amazonaws.com
            Action: sts:AssumeRole
      Policies:
        - PolicyName: LambdaPolicy
          PolicyDocument:
            Version: "2012-10-17"
            Statement:
              - Effect: Allow
                Action:
                  - organizations:ListRoots
                Resource: "*"

  GetOrganizationRootId:
    Type: Custom::GetOrganizationRootId
    Properties:
      ServiceToken: !GetAtt GetRootIdLambda.Arn

  ### StackSet Definition ###
  AnodotAccountsStackSet:
    Type: AWS::CloudFormation::StackSet
    Properties:
      StackSetName: !Ref StackSetName
      Description: Deploys Anodot Role to all linked accounts in Organization
      PermissionModel: SERVICE_MANAGED
      AutoDeployment:
        Enabled: true
        RetainStacksOnAccountRemoval: false
      StackInstancesGroup:
        - DeploymentTargets:
            OrganizationalUnitIds:
              - !GetAtt GetOrganizationRootId.RootId
          Regions:
            - us-east-1
      Parameters:
        - ParameterKey: ExternalId
          ParameterValue: !Ref ExternalId
      TemplateURL: !Sub ${ExternalLinks.AwsOnboardingCFTJson}
      Capabilities:
        - CAPABILITY_NAMED_IAM
`;
