import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isPasswordValid, isUsernameValid } from 'users/utils/userUtil';
import { Routes } from 'shared/constants/routes';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { ReactComponent as PersonIcon } from './assets/person.svg';
import { ReactComponent as K8SIcon } from './assets/k8s.svg';
import { ReactComponent as ArrowUpIcon } from './assets/arrow-up.svg';
import { ReactComponent as CloudIcon } from './assets/cloud.svg';
import { ReactComponent as BookIcon } from './assets/book.svg';
import MainDbImg from './assets/main-db.png';
import CostImg from './assets/cost.png';
import RegisterForm from './components/RegisterForm';
import styles from './Register.module.scss';
import Explanation from './components/Explanation';
import { verifyCodeApi } from '../../new-user-management/hooks/reactQuery/useRegistrationCode';
import { withOrgOnboardingContextProvider } from '../../new-user-management/context/OrgOnboardingContext';
import toast from '~/shared/components/andtComponents/Toast.jsx';
import externalLinksFunctions from 'shared/utils/external-links.util';

const RegisterFormTypes = {
  REGISTER: 'Register',
  REGISTRATION_COMPLETED: 'Registration-Completed',
};

const LOGO_URL = (theme) => externalLinksFunctions.AwsThemeRegisterLogo({ theme });

class Register extends Component {
  static propTypes = {
    isMSP: PropTypes.bool,
    theme: PropTypes.object,
    navigate: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    isNewUserManagement: PropTypes.bool,
    brandContext: PropTypes.object.isRequired,
    onboardOrganization: PropTypes.shape({
      mutateAsync: PropTypes.func.isRequired,
    }),
  };
  static defaultProps = {
    isMSP: false,
    theme: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      code: '',
      jobTitle: '',
      companyName: '',
      username: '',
      password: '',
      displayName: '',
      providerId: '',
      campaignId: '',
      messageId: '',
      resultStrings: [],
      formType: RegisterFormTypes.REGISTER,
      confirmCode: '',
      passwordValid: false,
      usernameValid: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const fullname = params.get('full_name');
    const jobTitle = params.get('job_title');
    const companyName = params.get('company_name');
    const username = params.get('email');
    const providerId = params.has('provider_id') ? params.get('provider_id') : '';
    const campaignId = params.has('campaign_id') ? params.get('campaign_id') : '';
    const messageId = params.has('message_id') ? params.get('message_id') : '';

    const { brandContext } = this.props;

    let usernameValid = false;
    let usernameResultStrings = [];
    if (username) {
      const { validResult, resultStrings } = isUsernameValid(username);
      usernameValid = validResult;
      usernameResultStrings = resultStrings;
    }
    const displayName = params.get('nick_name');
    this.setState({
      fullname,
      jobTitle,
      companyName,
      username,
      displayName,
      resultStrings: usernameResultStrings,
      usernameValid,
      providerId,
      campaignId,
      messageId,
      brandContext,
    });
  }

  getCompanyName = () => {
    const { theme } = this.props;
    if (theme) {
      return theme.companyName;
    }
    return this.state.brandContext.name;
  };

  handleChange = (event) => {
    const { isNewUserManagement } = this.props;
    event.preventDefault();
    if (event.target.name === 'password' && !isNewUserManagement) {
      const { validResult, resultStrings } = isPasswordValid(event.target.value);
      this.setState({ [event.target.name]: event.target.value, resultStrings, passwordValid: validResult });
    } else if (event.target.name === 'username') {
      const { validResult, resultStrings } = isUsernameValid(event.target.value);
      this.setState({ [event.target.name]: event.target.value, resultStrings, usernameValid: validResult });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleRegisterSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const { isNewUserManagement, isMSP, theme, onboardOrganization } = this.props;
    const { password, displayName, username, code } = this.state;
    this.setState({ isValidating: true });
    if (isNewUserManagement) {
      if (!code) {
        this.setState({ resultStrings: ['Verification code is required.'], isValidating: false });
        return;
      }
      try {
        const { valid } = await verifyCodeApi(username, code);
        if (!valid) {
          this.setState({ resultStrings: ['Verification code is invalid.'], isValidating: false });
          return;
        }
        const { companyName, providerId, campaignId, messageId } = this.state;
        const { firstName, lastName } = this.splitFullNameToFirstAndLast();
        await onboardOrganization.mutateAsync({
          name: companyName,
          userEmail: username.toLowerCase(),
          userFirstName: firstName,
          userLastName: lastName,
          campaignId,
          providerId,
          messageId,
          theme: theme ? theme.name : undefined,
          isResellerMode: isMSP,
        });
        this.setState({ isValidating: false, formType: RegisterFormTypes.REGISTRATION_COMPLETED });
        toast.success('Registration completed successfully. Please check your email to confirm your account.');
      } catch (e) {
        toast.error(e.response?.data?.message || 'Something went wrong, please try again later or contact support.');
        this.setState({ isValidating: false });
      }
      return;
    }
    try {
      const { usersStore } = this.props;
      const loweredCaseUsername = username.toLowerCase();
      const registerResult = await usersStore.registerUser(loweredCaseUsername, displayName, password);
      if (registerResult.result) {
        this.handleConfirmRegisterSubmit();
      } else {
        this.setState({ resultStrings: [registerResult.msg], isValidating: false });
      }
    } catch {
      this.setState({ isValidating: false });
    }
  };

  splitFullNameToFirstAndLast = () => {
    const { fullname } = this.state;
    const [firstName, ...lastNameArr] = (fullname || '').trim().split(/\s+/);
    const lastName = lastNameArr.join('-');
    return { firstName, lastName };
  };

  handleConfirmRegisterSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const { username, password, confirmCode, displayName, jobTitle, companyName, providerId, campaignId, messageId } =
      this.state;
    const { firstName, lastName } = this.splitFullNameToFirstAndLast();
    const { usersStore, isMSP, theme } = this.props;
    this.setState({ isValidating: true });
    let confirmResult = {
      result: false,
      msg: 'There was an error confirming your account please check your confirmation code.',
    };
    try {
      const loweredCaseUsername = username.toLowerCase();
      confirmResult = await usersStore.deprecatedConfirmUser(
        firstName,
        lastName,
        jobTitle,
        companyName,
        loweredCaseUsername,
        displayName,
        password,
        confirmCode,
        providerId,
        campaignId,
        messageId,
        isMSP,
        theme ? theme.name : undefined,
      );
      if (confirmResult.result === true) {
        toast.success('Registration completed successfully.');
        this.setState({ isValidating: false, formType: RegisterFormTypes.REGISTRATION_COMPLETED });
      } else {
        this.setState({ isValidating: false, resultStrings: [confirmResult.msg] });
      }
    } catch {
      this.setState({ isValidating: false, resultStrings: [confirmResult.msg] });
    }
  };

  redirectRegisteredUser = () => {
    const { navigate } = this.props;
    setTimeout(() => {
      navigate(`${Routes.LOG_IN}`);
      window.location.reload();
    }, 5000);
  };

  renderExplanationsText = () => {
    const { isMSP } = this.props;
    if (isMSP) {
      return (
        <div>
          <Explanation
            Icon={CloudIcon}
            title="Transform your cloud reseller business"
            text="Deliver best-in-class multicloud visibility, optimization, and monitoring to your customers with
              the most trustworthy and accurate management capabilities for billing, credit management, and invoicing."
          />
          <Explanation
            Icon={PersonIcon}
            title="Manage your customers seamlessly"
            text="Quickly add and manage your customers. See all their invoices and usage reports in one place.
              Switch between customer accounts and pricing on the fly."
          />
          <Explanation
            Icon={CalendarIcon}
            title="A single view of every customer"
            text={`${this.getCompanyName()} for Cloud Costs for you — and for your customers.
              Manage and optimize your cloud spending with an easy-to-use, intuitive tool that’s accessible to anyone.`}
          />
          <Explanation
            Icon={BookIcon}
            title="Simplify & automate internal operations"
            text="Quickly bill and invoice your customers with the adjusted rates you choose.
              Send one-time and recurring reports to show customers what they are spending"
          />
        </div>
      );
    }
    return (
      <div>
        <Explanation
          Icon={CalendarIcon}
          title="Visualize your multicloud and Kubernetes costs"
          text="See cost causation and allocate spend with deep visibility, granular detail, and reporting"
        />
        <Explanation
          Icon={PersonIcon}
          title="Optimize how you use and purchase cloud"
          text="Easy-to-action savings recommendations surface the most relevant and actionable optimizations"
        />
        <Explanation
          Icon={ArrowUpIcon}
          title="Monitor your spending and enable FinOps"
          text="ML-powered forecasting and anomaly detection enable you to continuously control costs and avoid bill shock"
        />
        <Explanation
          Icon={K8SIcon}
          title="Understand your Kubernetes cost"
          text={`${this.getCompanyName()} provides granular visibility into your Kubernetes cost and combines it with
           your non-containerized costs and business metrics, so you can get an accurate view of how much it
            costs to run a microservice, feature, etc.`}
        />
      </div>
    );
  };

  renderForms = () => {
    const { usernameValid, passwordValid, isValidating, jobTitle, fullname, companyName, username, password, code } =
      this.state;
    const { formType, resultStrings } = this.state;
    const { theme, isNewUserManagement } = this.props;
    switch (formType) {
      case RegisterFormTypes.REGISTER:
        return (
          <>
            <RegisterForm
              handleSubmit={this.handleRegisterSubmit}
              handleChange={this.handleChange}
              fullname={fullname}
              jobTitle={jobTitle}
              code={code}
              companyName={companyName}
              companyExampleName={this.getCompanyName()}
              username={username}
              password={password}
              isNewUserManagement={isNewUserManagement}
            />
            <div>
              {resultStrings.map((resultString) => (
                <h5 key={resultString} style={{ color: '#FF494D' }}>
                  {resultString}
                </h5>
              ))}
            </div>

            <br />
            <div style={{ width: 'fit-content', margin: 'auto' }}>
              <Button
                onClick={this.handleRegisterSubmit}
                disabled={
                  !usernameValid ||
                  !companyName ||
                  (!isNewUserManagement && !passwordValid) ||
                  (isNewUserManagement && code?.length !== 6)
                }
                isLoading={isValidating}
                text="Register"
                overrideStyles={{ width: 220, height: 40 }}
              />
            </div>
            <div className={styles.linksBlock}>
              <p className={styles.loginLink}>
                Already have an account? <Link to={Routes.LOG_IN}>Login</Link>
              </p>
              <p>
                <span>By clicking the Register button you are agreeing to the {this.getCompanyName()}</span>
                <br />
                <a rel="noopener noreferrer" target="_blank" href={theme ? theme.termsUrl : Routes.TERMS_AND_CONDS}>
                  Terms & Conditions
                </a>
                <span> and </span>
                <a rel="noopener noreferrer" target="_blank" href={theme ? theme.privacyURL : Routes.PRIVACY_POLICY}>
                  Privacy Policy
                </a>
              </p>
              {!theme || theme.contactUrl ? (
                <p>
                  <span>Have a question? </span>
                  <a rel="noopener noreferrer" target="_blank" href={theme ? theme.contactUrl : Routes.CONTACT_US}>
                    Contact Us
                  </a>
                </p>
              ) : null}
            </div>
          </>
        );
      case RegisterFormTypes.REGISTRATION_COMPLETED:
        if (isNewUserManagement) {
          return (
            <span className="text-center mt-4">
              Registration Completed - Please check your inbox with an email to confirm your account.
              <br />
              <a href={Routes.LOG_IN}>Log in</a>
            </span>
          );
        }
        this.redirectRegisteredUser();
        return <span className="text-center mt-4">Registration Completed - Redirecting to login page</span>;
      default:
        return null;
    }
  };

  render() {
    const { isMSP, theme, brandContext } = this.props;
    return (
      <div className={styles.register}>
        <div>
          <h2 className={styles.title}>
            {isMSP
              ? `${this.getCompanyName()} Cloud Management for Reseller`
              : `${this.getCompanyName()} Cloud Cost Management`}
          </h2>
          <p className={styles.subTitle}>
            {isMSP
              ? `Improve profitability, simplify your internal processes, and make your 
                   managed cloud offering stand out with the most advanced cost management platform`
              : 'Understand and control your multicloud and Kubernetes costs'}
          </p>
          {this.renderExplanationsText()}
          <div className={styles.slideSet}>
            <img src={MainDbImg} alt="main-db" />
            <img src={CostImg} alt="cost" />
          </div>
        </div>
        <div className={styles.accountCard}>
          {theme ? (
            <img src={LOGO_URL(theme.name)} alt="logo" />
          ) : (
            brandContext.loginLogo.asComponent({ className: styles.loginLogo })
          )}

          <h6 className={styles.accountHead}>Start your free 30-day trial</h6>
          {this.renderForms()}
        </div>
      </div>
    );
  }
}

const ObserverRegisterPage = withOrgOnboardingContextProvider(observer(Register));
export default ObserverRegisterPage;
