import React, { useMemo } from 'react';
import Select, { createFilter, StylesConfig } from 'react-select';
import { palette } from 'shared/constants/colorsConstants';
import classNames from 'classnames';
import {
  titleByChannelType,
  iconByChannelType,
  groupedChannelTypes,
} from 'users/containers/Channels/channelsHelpers.js';
import styles from './RecipientsSelect.module.scss';

export interface Recipient {
  id: string;
  anodotName?: string;
  recipientData?: {
    name?: string;
  };
  dstId?: string;
}

interface Channel {
  channelType: string;
  name: string;
  recipients: Recipient[];
}

interface GroupedChannels {
  groupedChannels: Channel[];
}

interface SelectOption {
  value: string;
  label: React.ReactNode;
  [key: string]: any; // For additional recipient data
}

interface RecipientsSelectProps {
  channels?: Channel[];
  isLoading?: boolean;
  savedRecipients?: Recipient[];
  onChangeHandler: (selected: any) => void;
  isDisabled?: boolean;
  menuPosition?: 'absolute' | 'fixed';
  menuPlacement?: 'auto' | 'bottom' | 'top';
  className?: string;
  height?: number | string;
  selectStylesConfig?: StylesConfig;
  isMulti?: boolean;
}

const selectStyles = ({ height }: { height?: number | string }) => ({
  container: (defaultStyles: any) => ({ ...defaultStyles, height, borderRadius: 5 }),
  control: (baseStyle: any, state: { isFocused: boolean; isDisabled: boolean }) => ({
    ...baseStyle,
    borderColor: state.isFocused ? palette.blue[400] : 'transparent',
    backgroundColor: state.isDisabled ? palette.gray[100] : palette.gray[200],
    borderWidth: '2px',
    borderRadius: '6px',
    boxShadow: 'unset',
    minHeight: '0',
    height,
  }),
  valueContainer: (baseStyle: any) => ({
    ...baseStyle,
    padding: '2px 4px',
    height,
  }),
  clearIndicator: (baseStyle: any) => ({
    ...baseStyle,
    padding: '0',
    margin: '6px',
    height,
  }),
  dropdownIndicator: (baseStyle: any) => ({
    ...baseStyle,
    padding: '0',
    margin: '6px',
  }),
  indicatorsContainer: (baseStyle: any) => ({
    ...baseStyle,
    height,
  }),
  placeholder: (baseStyle: any, state: { isDisabled: boolean }) => ({
    ...baseStyle,
    color: state.isDisabled ? palette.gray[400] : palette.gray[450],
  }),
  multiValue: (baseStyle: any) => ({
    ...baseStyle,
    backgroundColor: 'white',
    alignItems: 'center',
    border: `1px solid ${palette.gray[300]}`,
    borderRadius: '6px',
  }),
  multiValueLabel: (baseStyle: any) => ({
    ...baseStyle,
    color: palette.gray[450],
    backgroundColor: 'white',
    borderRadius: '6px',
    fontSize: '13px',
    fontWeight: 500,
    paddingLeft: '4px',
    padding: '0 4px',
  }),
  multiValueRemove: (baseStyle: any, state: { isDisabled: boolean }) => ({
    ...baseStyle,
    color: palette.gray[300],
    padding: 0,
    marginRight: '2px',
    borderRadius: '50%',
    display: state.isDisabled ? 'none' : 'flex',
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
});

const RecipientsSelect: React.FC<RecipientsSelectProps> = ({
  channels,
  isLoading,
  savedRecipients = [],
  onChangeHandler,
  isDisabled,
  menuPosition = 'absolute',
  menuPlacement = 'auto',
  className = '',
  height,
  selectStylesConfig,
  isMulti = true,
}) => {
  const { options = [], selectedOptions = [] } = useMemo(() => {
    if (!channels || channels.length === 0) {
      return { options: [], selectedOptions: [] };
    }

    const groupedChannelsList: GroupedChannels[] = groupedChannelTypes
      .map((channelType) => ({
        groupedChannels: channels.filter((c) => c.channelType === channelType),
      }))
      .filter(({ groupedChannels }) => groupedChannels.length > 0);

    const options = [
      ...channels
        .filter((c) => !groupedChannelTypes.includes(c.channelType))
        .map((channel) => ({
          label: `${titleByChannelType[channel.channelType] || channel.channelType}: ${channel.name}`,
          options: channel.recipients
            .filter(({ id }) => !savedRecipients.some((r) => id === r.id))
            .map((recipient) => ({
              ...recipient,
              value: recipient.id,
              label: (
                <>
                  {iconByChannelType[channel.channelType]}{' '}
                  {recipient.anodotName || recipient.recipientData?.name || recipient.dstId}
                </>
              ),
            })),
        })),
      ...groupedChannelsList.map(({ groupedChannels }) => ({
        label: titleByChannelType[groupedChannels[0].channelType],
        options: groupedChannels.reduce<SelectOption[]>(
          (acc, channel) => [
            ...acc,
            ...channel.recipients
              .filter(({ id }) => !savedRecipients.some((r) => id === r.id))
              .map((recipient) => ({
                ...recipient,
                value: recipient.id,
                label: (
                  <>
                    {iconByChannelType[channel.channelType]}{' '}
                    {recipient.anodotName || recipient.recipientData?.name || recipient.dstId}
                  </>
                ),
              })),
          ],
          [],
        ),
      })),
    ];

    return { options };
  }, [channels, savedRecipients]);

  return (
    <Select
      isMulti={isMulti}
      isDisabled={isDisabled}
      isLoading={isLoading}
      options={options}
      onChange={onChangeHandler}
      className={classNames(styles.select, className)}
      classNamePrefix="recipients-select"
      styles={selectStylesConfig || selectStyles({ height })}
      menuPosition={menuPosition}
      menuPlacement={menuPlacement}
      filterOption={createFilter({ ignoreAccents: false })}
    />
  );
};

export default RecipientsSelect;
