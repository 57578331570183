import React from 'react';
import { BrandTypes } from './enums';
import { Brand } from './types';

import anodotLogo from '../../../shared/img/images/cloud_cost_logo.png';
import umbrellaLogo from '../../../shared/img/images/Umbrella-logo.png';
import umbrellaLoginLogo from '../../../shared/img/images/Umbrella-login-logo.png';

// @ts-ignore
import { ClarityLogo } from '@pileus-cloud/anodot-frontend-common';

const anodotBrand: Brand = {
  name: 'Anodot',
  domain: 'anodot.com',
  suppotEmail: 'support@anodot.com',
  realm: {
    excludeDomain: 'pileuscloud.com'
  },
  loginLogo: {
    src: anodotLogo,
    alt: 'Anodot for Cloud Cost',
    asComponent: (props: any) => {
      return <img {...props} src={anodotLogo} alt={'Anodot for Cloud Cost'} />;
    },
  },
  logo: {
    src: '',
    alt: '',
    asComponent: (props: any) => {
      return <ClarityLogo />;
    },
  },
};

const umbrellaBrand: Brand = {
  name: 'Umbrella',
  domain: 'umbrellacost.com',
  suppotEmail: 'support@umbrellacost.com',
  realm: {
    excludeDomain: 'pileuscloud.com'
  },
  loginLogo: {
    src: umbrellaLoginLogo,
    alt: 'Umbrella for Cloud Cost',
    asComponent: (props: any) => {
      return <img {...props} src={umbrellaLoginLogo} alt={'Umbrella for Cloud Cost'} />;
    },
  },
  logo: {
    src: umbrellaLogo,
    alt: 'Umbrella for Cloud Cost',
    asComponent: (props: any) => {
      return <img {...props} src={umbrellaLogo} alt={'Umbrella for Cloud Cost'} />;
    },
  },
};

export const BrandConfiguration: Record<string, Brand> = {
  [BrandTypes.Anodot]: anodotBrand,
  [BrandTypes.Umbrella]: umbrellaBrand,
};
