import React from 'react';
import PropTypes from 'prop-types';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  TableColumnVisibility,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Col } from 'reactstrap';
import { RdsIsActiveValues } from 'usage/constants/usageConstants';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { IconButton } from '@mui/material';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

class WeightsTable extends React.Component {
  static propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    currenciesColumns: PropTypes.object.isRequired,
    deleteHandler: PropTypes.object.isRequired,
    editHandler: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { columns, columnWidths, tableColumnExtensions, defaultHiddenColumnNames, currenciesColumns } = this.props;
    this.state = {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames: defaultHiddenColumnNames,
      currenciesColumns,
      filteringStateColumnExtensions: [{ columnName: 'userAction', filteringEnabled: false }],
    };
  }

  userActionsFormatter = (data) => (
    <div style={{ display: 'flex' }}>
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={OrganizationEntityCategory.OrganizationManagement}
        action={Action.Update}
      >
        <Tooltip title="Delete">
          <IconButton onClick={() => this.props.deleteHandler(data.row.id)} size="large">
            <GenerateIcon iconName={ICONS.delete.name} size={'2xs'} />
          </IconButton>
        </Tooltip>
      </ReadOnlyDisplayWrapper>
      <Tooltip title="Edit">
        <IconButton onClick={() => this.props.editHandler(data.row)} size="large">
          <GenerateIcon iconName={ICONS.pencil.name} size={'2xs'} />
        </IconButton>
      </Tooltip>
    </div>
  );

  currenciesFormatter = (value) => `${this.props.getCurrencyNumber(value.value, 1)}`;

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  render() {
    const {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames,
      currenciesColumns,
      filteringStateColumnExtensions,
    } = this.state;
    const { rows, defaultSorting } = this.props;
    const csvModifiedRows = JSON.parse(JSON.stringify(rows));
    csvModifiedRows.map((row) => {
      if (row.isActive === RdsIsActiveValues.NOT_ACTIVE) {
        row.isActive = 'No';
        return row;
      }

      row.isActive = 'Yes';
      return row;
    });
    return (
      <div style={{ width: '100%' }} ref={this.refCallback}>
        <Col xs={12} md={12} lg={12} xl={12}>
          <div className="page-wrapper">
            <Grid rows={rows} columns={columns}>
              <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
              <IntegratedFiltering />
              <SortingState defaultSorting={defaultSorting} />
              <IntegratedSorting />
              {currenciesColumns && (
                <DataTypeProvider for={currenciesColumns} formatterComponent={this.currenciesFormatter} />
              )}
              <DataTypeProvider for={['userAction']} formatterComponent={this.userActionsFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </div>
        </Col>
      </div>
    );
  }
}

const ObserverWeightsTable = withUserSettingsConsumer(WeightsTable);
export default ObserverWeightsTable;
