import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Grid, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import {
  compareFormatterNumber,
  getFirstDayOfMonth,
  getSearchParamsPanel,
} from 'app/containers/MainDashboard/utils.js';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import TableWrapper from 'shared/components/tables/TableWrapper.jsx';
import useTable from 'shared/hooks/customHooks/useTable.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import DateFilter from 'shared/modules/dateFilter.js';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import RegionMap from 'app/containers/MainDashboard/components/RegionMap.jsx';
import NavigateIcon from 'app/containers/MainDashboard/components/NavigateIcon.jsx';
import { PANEL_COMPONENT_TYPES } from 'usage/containers/CustomDashboard/constants/customDashboardConstants.js';
import SaveDashboardPanel from 'app/containers/MainDashboard/components/SaveDashboardPanel.jsx';
import styles from './regionCost.module.scss';
import mainStyles from '../mainDashboardCommon.module.scss';

const getColumns = (getCurrencyNumber) => {
  return [
    { name: 'regionName', title: 'Region' },
    { name: 'usageCost', title: 'MTD Costs', getCellValue: (row) => `${getCurrencyNumber(row.usageCost)}` },
    { name: 'totalPercent', title: '% of Total Cost', getCellValue: (row) => `${row.totalPercent.toFixed(2)}%` },
  ];
};

const columnExtensions = [
  { columnName: 'monthlyTotalCost' },
  { columnName: 'usageCost', compare: compareFormatterNumber },
  { columnName: 'totalPercent' },
];

const columnWidths = [
  { columnName: 'regionName', width: 200 },
  { columnName: 'usageCost', width: 150 },
  { columnName: 'totalPercent', width: 150 },
];
const PercentColumnFormatter = ({ value }) => <PercentTag percent={value} />;

PercentColumnFormatter.propTypes = {
  value: PropTypes.number.isRequired,
};

const RegionCost = ({ hideSaveDashboard = false }) => {
  const { appStore } = useRootStore();
  const { updatePanelLoadingState, getDynamicFilters } = useMainDashboardContext();
  const { NewTableWrapper } = useTable();
  const cueHook = useCueData();
  const { getCurrencyNumber } = useUserSettingsContext();
  const params = {
    ...PANELS[PANELS_TYPES.REGION_COST].params,
    start: getFirstDayOfMonth(moment(DateFilter.getDate())),
    end: moment(DateFilter.getDate()).format('YYYY-MM-DD'),
    isPpApplied: appStore.isPpApplied,
    ...getDynamicFilters(),
  };
  const { data, isLoading } = cueHook.fetchCueData(params);
  const [sorting, setSorting] = useState([{ columnName: 'usageCost', direction: 'desc' }]);

  const regionsData = useMemo(() => {
    if (!data) {
      return [];
    }
    const displayRegionsData = data.filter((region) => region.total_cost?.toFixed(2) > 0);
    const totalCostAllRegions = displayRegionsData.reduce((acc, regionData) => acc + regionData.total_cost, 0);

    const regionTotals = displayRegionsData.reduce((acc, regionData) => {
      const region = regionData.group_by;
      const totalCost = regionData.total_cost;

      const existingRegion = acc.find((r) => r.regionName === region);
      if (existingRegion) {
        existingRegion.usageCost += totalCost;
      } else {
        acc.push({ regionName: region, regionTag: region, usageCost: totalCost });
      }

      return acc;
    }, []);

    return {
      data: regionTotals.map((region) => ({
        ...region,
        totalPercent: (region.usageCost / totalCostAllRegions) * 100,
      })),
      totalRegionsCost: displayRegionsData
        .filter((r) => r.region !== 'Global')
        .reduce((acc, regionData) => acc + regionData.total_cost, 0),
    };
  }, [data]);

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.REGION_COST, isLoading);
  }, [isLoading]);

  const gridHeight = useMemo(() => {
    return regionsData?.data?.length > 10 ? 530 : (regionsData?.data?.length * 50 || 50) + 32;
  }, [regionsData?.data]);

  return (
    <div className={classNames(mainStyles.panelWrapper, mainStyles.fullWidth)}>
      {isLoading ? (
        <Spinner className={mainStyles.spinner} size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <>
          <div className={mainStyles.headerRow}>
            <span className={mainStyles.header}>{PANELS[PANELS_TYPES.REGION_COST].title}</span>
            <div className={styles.flexCenter}>
              <NavigateIcon
                getSearchParams={() => getSearchParamsPanel(params, appStore.isPpApplied)}
                id={PANELS_TYPES.REGION_COST}
              />
              {!hideSaveDashboard && <SaveDashboardPanel panelType={PANEL_COMPONENT_TYPES.REGION_MAP} />}
            </div>
          </div>
          <div className={styles.flexWrapper}>
            <NewTableWrapper className={mainStyles.halfWidthPanelWrapper}>
              <div className={classNames('sub-table', styles.scroll)} style={{ height: gridHeight }}>
                <Grid rows={regionsData?.data || []} columns={getColumns(getCurrencyNumber)}>
                  <SortingState sorting={sorting} onSortingChange={setSorting} />
                  <IntegratedSorting columnExtensions={columnExtensions} />
                  <TableWrapper virtualcolumnExtensions={columnExtensions} />
                  <TableColumnResizing columnWidths={columnWidths} />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </div>
            </NewTableWrapper>
            <div className={classNames(styles.mapWrapper, mainStyles.halfWidthPanelWrapper)}>
              <RegionMap regionData={regionsData} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

RegionCost.propTypes = {
  hideSaveDashboard: PropTypes.bool,
};
export default RegionCost;
