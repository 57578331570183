import React, { useState } from 'react';
import { SelectLinkedAccountField } from './SelectLinkAccountField.tsx';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import { AddClusterFields, addClusterLabels } from '../config.ts';
import SwitchButton from 'shared/components/andtComponents/Switch';
import classNames from 'classnames';
import Input from 'shared/components/andtComponents/Input';
import styles from '../K8sOnboarding.module.scss';
import { useFormContext } from 'react-hook-form';
import HttpRequestsField from './HttpRequestsField.tsx';

const useGetFieldsList = () => {
  const [openMap, setOpenMap] = useState({});
  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const onInputChange = (fieldName: string) => (e) => {
    setValue(fieldName, e.target.value, { shouldValidate: true });
    clearErrors(fieldName);
  };

  const onSwitch = (key: AddClusterFields) => () =>
    setOpenMap((d) => ({
      ...d,
      [key]: !d[key],
    }));

  const fieldsList = [
    {
      render: () => (
        <SelectLinkedAccountField
          onChange={(value) => {
            setValue(AddClusterFields.linkedAccountId, value, { shouldValidate: true });
          }}
          value={getValues(AddClusterFields.linkedAccountId)}
          isInvalid={!!errors[AddClusterFields.linkedAccountId]}
        />
      ),
      key: AddClusterFields.linkedAccountId,
    },
    {
      render: () => (
        <InputWithLabel
          label={addClusterLabels[AddClusterFields.clusterName]?.label}
          fieldName={AddClusterFields.clusterName}
          onChange={onInputChange(AddClusterFields.clusterName)}
          value={getValues(AddClusterFields.clusterName)}
          name={AddClusterFields.clusterName}
          isInvalid={!!errors[AddClusterFields.clusterName]}
          invalidComponent={errors[AddClusterFields.clusterName]?.message || errors[AddClusterFields.clusterName]}
          required
        />
      ),
      key: AddClusterFields.clusterName,
    },
    {
      render: () => (
        <InputWithLabel
          label={addClusterLabels[AddClusterFields.agentNamespace]?.label}
          fieldName={AddClusterFields.agentNamespace}
          onChange={onInputChange(AddClusterFields.agentNamespace)}
          value={getValues(AddClusterFields.agentNamespace)}
          isInvalid={!!errors[AddClusterFields.agentNamespace]}
          invalidComponent={errors[AddClusterFields.agentNamespace]?.message || errors[AddClusterFields.agentNamespace]}
        />
      ),
      key: AddClusterFields.agentNamespace,
    },
    {
      render: () => (
        <InputWithLabel
          label={addClusterLabels[AddClusterFields.prometheusUrl]?.label}
          fieldName={AddClusterFields.prometheusUrl}
          onChange={onInputChange(AddClusterFields.prometheusUrl)}
          value={getValues(AddClusterFields.prometheusUrl)}
          isInvalid={!!errors[AddClusterFields.prometheusUrl]}
          invalidComponent={errors[AddClusterFields.prometheusUrl]?.message || errors[AddClusterFields.prometheusUrl]}
        />
      ),
      key: AddClusterFields.prometheusUrl,
    },
    {
      render: () => (
        <SwitchButton
          label={'Connecting to Prometheus requires user name and password'}
          isChecked={!!openMap[AddClusterFields.prometheusConnectingCreds_block]}
          onChange={onSwitch(AddClusterFields.prometheusConnectingCreds_block)}
        />
      ),
      key: AddClusterFields.prometheusConnectingCreds_block,
    },
    {
      render: () => (
        <div className={commonStyles.inputsNestedContainer}>
          <InputWithLabel
            label={'User name'}
            disabled={false}
            isInvalid={false}
            invalidComponent={false}
            onChange={onInputChange(AddClusterFields.prometheusConnectingCreds_username)}
            value={getValues(AddClusterFields.prometheusConnectingCreds_username)}
            fieldName={AddClusterFields.prometheusConnectingCreds_username}
          />
          <InputWithLabel
            style={{ marginTop: '16px' }}
            label={'Password'}
            disabled={false}
            isInvalid={false}
            invalidComponent={false}
            onChange={onInputChange(AddClusterFields.prometheusConnectingCreds_password)}
            value={getValues(AddClusterFields.prometheusConnectingCreds_password)}
            fieldName={AddClusterFields.prometheusConnectingCreds_password}
          />
        </div>
      ),
      key: AddClusterFields.prometheusConnectingCreds_username,
      disabled: !openMap[AddClusterFields.prometheusConnectingCreds_block],
    },
    {
      render: () => (
        <SwitchButton
          label={'Special HTTP request headers are required'}
          isChecked={!!openMap[AddClusterFields.httpHeaders_block]}
          onChange={onSwitch(AddClusterFields.httpHeaders_block)}
        />
      ),
      key: AddClusterFields.httpHeaders_block,
    },
    {
      render: () => <HttpRequestsField setValue={setValue} value={getValues(AddClusterFields.httpHeaders)} />,
      key: AddClusterFields.httpHeaders,
      disabled: !openMap[AddClusterFields.httpHeaders_block],
    },
    {
      render: () => (
        <SwitchButton
          label={'Define unique job name for metric collection'}
          isChecked={!!openMap[AddClusterFields.uniqueJobs_block]}
          onChange={onSwitch(AddClusterFields.uniqueJobs_block)}
        />
      ),
      key: AddClusterFields.uniqueJobs_block,
    },
    {
      render: () => (
        <div className={commonStyles.inputsNestedContainer}>
          <InputWithLabel
            label={'Using non-default name for the kube-state-metrics job'}
            disabled={false}
            isInvalid={false}
            invalidComponent={false}
            onChange={onInputChange(AddClusterFields.kubeStateMetricsJobName)}
            value={getValues(AddClusterFields.kubeStateMetricsJobName)}
            fieldName={AddClusterFields.kubeStateMetricsJobName}
          />
          <InputWithLabel
            style={{ marginTop: '16px' }}
            label={'Using non-default name for the kubelet job'}
            disabled={false}
            isInvalid={false}
            invalidComponent={false}
            onChange={onInputChange(AddClusterFields.kubeletJobName)}
            value={getValues(AddClusterFields.kubeletJobName)}
            fieldName={AddClusterFields.kubeletJobName}
          />
        </div>
      ),
      key: AddClusterFields.kubeStateMetricsJobName,
      disabled: !openMap[AddClusterFields.uniqueJobs_block],
    },
    {
      render: () => (
        <SwitchButton
          label={'Prometheus stores metrics from other clusters'}
          isChecked={!!openMap[AddClusterFields.otherMetrics_block]}
          onChange={onSwitch(AddClusterFields.otherMetrics_block)}
        />
      ),
      key: AddClusterFields.otherMetrics_block,
    },
    {
      render: () => (
        <div className={commonStyles.inputsNestedContainer}>
          <div>Label used to identify metrics from this cluster:</div>
          <div className={classNames('d-flex align-items-center mt-2')}>
            <div className={commonStyles.inputBox}>
              <label htmlFor={AddClusterFields.otherMetrics_key}>Label key</label>
              <Input
                className={styles.noMinWidth}
                disabled={false}
                isInvalid={false}
                invalidComponent={false}
                onChange={onInputChange(AddClusterFields.otherMetrics_key)}
                value={getValues(AddClusterFields.otherMetrics_key)}
                name={AddClusterFields.otherMetrics_key}
              />
            </div>
            <div className={classNames(commonStyles.inputBox, 'ms-3')}>
              <label htmlFor={AddClusterFields.otherMetrics_value}>Label value</label>
              <Input
                className={styles.noMinWidth}
                overrideStyles={{ marginTop: '16px' }}
                disabled={false}
                isInvalid={false}
                invalidComponent={false}
                onChange={onInputChange(AddClusterFields.otherMetrics_value)}
                value={getValues(AddClusterFields.otherMetrics_value)}
                name={AddClusterFields.otherMetrics_value}
              />
            </div>
          </div>
        </div>
      ),
      key: AddClusterFields.otherMetrics_value,
      disabled: !openMap[AddClusterFields.otherMetrics_block],
    },
  ];

  return { fieldsList };
};

export default useGetFieldsList;

function InputWithLabel({ label, style = {}, fieldName = '', ...inputProps }) {
  return (
    <div className={commonStyles.inputField} style={style}>
      <label htmlFor={fieldName}>{label}</label>
      <div className={commonStyles.inputBox}>
        <Input name={fieldName} {...inputProps} />
      </div>
    </div>
  );
}
