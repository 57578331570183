import React from 'react';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as EmptyListImg } from './emptyListImg.svg';
import { ReactComponent as EmptyAddBellImg } from './emptyAddBellImg.svg';
import styles from '../../alerts.module.scss';

export interface EmptyAlertsProps {
  className?: string;
  canAdd?: boolean;
  onAdd?(e: any): void;
}

const EmptyAlerts: React.FC<EmptyAlertsProps> = ({ className, canAdd = false, onAdd }) => {
  const description = canAdd ? (
    <>
      <span>You have no alert rules yet.</span>
      <br />
      <span>Would you like to create alert rule?</span>
    </>
  ) : (
    'The option to add an alert is only available if the SP table contains data.'
  );
  return (
    <div className={classNames(className, styles.emptyListContainer)}>
      {canAdd ? <EmptyAddBellImg /> : <EmptyListImg />}
      <div>{description}</div>
      {canAdd && onAdd && (
        <div>
          <Button
            isGhost
            automationId="empty-add-button"
            text="Create Alert Rule"
            onClick={onAdd}
            icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
          />
        </div>
      )}
    </div>
  );
};

export default EmptyAlerts;
