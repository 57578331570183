import React, { useState } from 'react';
import classNames from 'classnames';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import styles from '../K8sOnboarding.module.scss';
import { AddClusterFields } from '../config.ts';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';

type Props = {
  setValue(key: string): void;
  value: string;
};

const HttpRequestsField = ({ setValue, value }) => {
  const [rows, setRows] = useState([true]);
  let parsedValues = [];
  try {
    parsedValues = JSON.parse(value);
  } catch (e) {}

  return (
    <div className={commonStyles.inputsNestedContainer}>
      {rows.map((row, index) => {
        const rowValue = parsedValues[index] || {};
        const key = Object.keys(rowValue)[0];
        const value = Object.values(rowValue)[0] || '';
        const onChange = (e, i, isKey = false) => {
          setValue(
            AddClusterFields.httpHeaders,
            JSON.stringify([
              ...parsedValues.slice(0, i),
              isKey ? { [e.target.value]: e.target.value.length ? value : '' } : { [key]: e.target.value },
              ...parsedValues.slice(i + 1),
            ]),
            { shouldValidate: true },
          );
        };
        return (
          <div className={classNames('d-flex align-items-center mb-2')}>
            <div className={commonStyles.inputBox}>
              <label htmlFor={`${AddClusterFields.httpHeaders}_key_${String(index)}`}>Key</label>
              <Input
                className={classNames(styles.noMinWidth)}
                name={`${AddClusterFields.httpHeaders}_key_${String(index)}`}
                value={key}
                onChange={(e) => onChange(e, index, true)}
              />
            </div>
            <div className={classNames(commonStyles.inputBox, 'ms-3')}>
              <label htmlFor={`${AddClusterFields.httpHeaders}_value_${String(index)}`}>Value</label>
              <Input
                className={styles.noMinWidth}
                name={`${AddClusterFields.httpHeaders}_value_${String(index)}`}
                value={value as string}
                onChange={(e) => onChange(e, index)}
                disabled={!key}
              />
            </div>
          </div>
        );
      })}
      <Button
        text={'+ Add More'}
        isTextButton
        onClick={() => {
          setRows((s) => [...s, true]);
          setValue(AddClusterFields.httpHeaders, JSON.stringify([...parsedValues, {}]), { shouldValidate: true });
        }}
      />
    </div>
  );
};

export default HttpRequestsField;
