import React from 'react';
import { ReactComponent as AccountNameSVG } from 'shared/img/images/account-name.svg';
import BucketRegionImage from './assets/BacketRegion.png';
import AccountIdImage from './assets/AccountId.png';
import BucketNameImage from './assets/BucketName.jpg';
import commonStyles from '../Pages.module.scss';
import styles from './DetailsPage.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const DetailsPageHelp = {};

DetailsPageHelp.accountId = {
  component: () => (
    <p className={commonStyles.helpText}>
      From the right side of the page, click on your user name and copy the <span>Account ID</span>.
    </p>
  ),
  image: AccountIdImage,
  imageHeight: 182,
};

DetailsPageHelp.bucketName = {
  component: () => {
    const brand = useBrand();
    return (
      <p className={commonStyles.helpText}>
        Navigate to the <span>S3</span> service, find the bucket you created for {brand.name} (which will host the CUR),
        and copy his <span>name</span>.
      </p>
    );
  },
  image: BucketNameImage,
  imageHeight: 182,
};

DetailsPageHelp.bucketRegion = {
  component: () => {
    const brand = useBrand();
    return (
      <p className={commonStyles.helpText}>
        Navigate to the <span>S3 bucket</span> you created for {brand.name}, Click on the Properties tab, and check your{' '}
        <span>bucket region</span>.
      </p>
    );
  },
  image: BucketRegionImage,
  imageHeight: 182,
};

DetailsPageHelp.accountName = {
  component: () => {
    const brand = useBrand();
    return (
      <div className={styles.accountNameHelp}>
        <p className={commonStyles.helpText}>Choose a display name for the account in {brand.name}</p>
        <AccountNameSVG />
      </div>
    );
  },
};

export default DetailsPageHelp;
