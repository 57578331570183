import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, RowDetailState, SelectionState } from '@devexpress/dx-react-grid';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import PropTypes from 'prop-types';
import {
  FILTER_NUMBER_OR_DATE_OPERATIONS,
  FILTER_OPERATIONS,
} from 'shared/components/andtComponents/TableComponents/const';
import {
  CustomFormatterWithTooltipAndComponent,
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import { calculateColumnWidths, getColumns, ROLES_COLUMNS } from '../consts';
import styles from './RolesTable.module.scss';
import RolesIcon from './RolesIcon';
import RoleIdCopy from './rolesTableComponents/RoleIdCopy';
import AddUsersToRole from './rolesTableComponents/AddUsersToRole';
import RoleActions from './RoleActions';
import RoleExpandedData from './rolesExpandedRowComponents/RoleExpandedData';
import EditRoleLinkedAccounts from './rolesTableComponents/EditRoleLinkedAccounts';
import RolesBulkOperations from './RolesBulkOperations';
import { useRolesContext } from './contexts/rolesContext';
import RolesNoData from 'users/containers/Organization/components/EmptyStates/RolesNoData';
import Spinner from 'shared/components/andtComponents/Spinner';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CreateEditRoleModal from './roleModals/CreateEditRoleModal/CreateEditRoleModal';
import Tooltip from 'shared/components/andtComponents/Tooltip';

const RolesTable = ({ roles, isLoading }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [rowToDuplicate, setRowToDuplicate] = useState();

  const [localColumnsWidth, setLocalColumnsWidth] = useState();

  useEffect(() => {
    const resizeFunction = () => calculateColumnWidths(setLocalColumnsWidth, Object.values(ROLES_COLUMNS));
    resizeFunction();
    window.addEventListener('resize', resizeFunction);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', resizeFunction);
  }, []);

  const { NewTableRow, NewTableSelectionBar, CustomToggleCell } = useTable();

  const { selectedRows, search, setSelectedRows, isAllExpanded, setIsAllExpanded } = useRolesContext();

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(roles?.map((row, index) => index));
    }
  }, [isAllExpanded, roles]);

  useEffect(() => {
    if (expandedRowIds?.length === 0) {
      setIsAllExpanded(false);
    }
  }, [expandedRowIds]);

  const renderRoleActions = ({ row }) => <RoleActions row={row} />;

  const NoDataCell = (props) => {
    return (
      <RolesNoData
        colSpan={0}
        mainText={!search && !roles?.length ? 'Roles were not defined yet' : null}
        subTexts={
          !search && !roles?.length
            ? [
                'We have provided the default roles to use as building blocks in creating the',
                'required roles of your organization.',
                'Check the “Built-in Roles” checkbox to view these building blocks.',
              ]
            : null
        }
        {...props}
      ></RolesNoData>
    );
  };
  const renderDuplicateRole = ({ row }) => {
    return (
      <Tooltip title="Duplicate Role" placement="top" arrow>
        <span>
          <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.Roles} action={Action.Update}>
            <Button
              automationId="duplicateRole"
              className={{ button: styles.duplicateRole }}
              icon={<GenerateIcon iconName={ICONS.copy.name} className={styles.addUsersIcon} />}
              onClick={() => {
                setRowToDuplicate(row);
              }}
              text=""
            />
          </ReadOnlyDisplayWrapper>
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        roles && (
          <Grid rows={roles} columns={getColumns(ROLES_COLUMNS)} automationId="roles-list">
            <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
            <IntegratedSelection />
            <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
            <DataTypeProvider
              for={[ROLES_COLUMNS.ROLE_NAME.columnName]}
              formatterComponent={(props) => (
                <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent>
                  <RolesIcon />
                </CustomFormatterWithTooltipAndComponent>
              )}
              availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.ROLE_ID.columnName]}
              formatterComponent={(props) => (
                <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent={false} isDisplayedOnlyOnHover>
                  <RoleIdCopy roleId={props?.row?.roleReferenceId} />
                </CustomFormatterWithTooltipAndComponent>
              )}
              availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.USERS_COUNT.columnName]}
              formatterComponent={(props) => <CustomFormatterWithTooltipOnlyIfLongText {...props} leftPadding />}
              availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.CREATED_AT.columnName]}
              formatterComponent={(props) => (
                <CustomFormatterWithTooltipOnlyIfLongText
                  value={props?.row?.createdAt ? moment(props?.row?.createdAt).format('MMM DD YYYY, hh:mm:ss A') : null}
                  isSmallText
                />
              )}
              availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.CREATED_BY.columnName]}
              formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
              availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.UPDATED_AT.columnName]}
              formatterComponent={(props) => (
                <CustomFormatterWithTooltipOnlyIfLongText
                  value={props?.row?.updatedAt ? moment(props?.row?.updatedAt).format('MMM DD YYYY, hh:mm:ss A') : null}
                  isSmallText
                />
              )}
              availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.UPDATED_BY.columnName]}
              formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
              availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.ROLE_DESCRIPTION.columnName]}
              formatterComponent={(props) => (
                <CustomFormatterWithTooltipOnlyIfLongText {...props} className={styles.roleDescription} />
              )}
              availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.DUPLICATE_ROLE.columnName]}
              formatterComponent={renderDuplicateRole}
            />
            <DataTypeProvider
              for={[ROLES_COLUMNS.ADD_LINKED_ACCOUNTS.columnName]}
              formatterComponent={EditRoleLinkedAccounts}
            />
            <DataTypeProvider for={[ROLES_COLUMNS.ADD_USERS.columnName]} formatterComponent={AddUsersToRole} />
            <DataTypeProvider for={[ROLES_COLUMNS.ACTIONS.columnName]} formatterComponent={renderRoleActions} />
            <TableWrapper
              virtual
              noDataCellComponent={(props) => <NoDataCell {...props}></NoDataCell>}
              rowComponent={(props) => (
                <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
              )}
              height="auto"
            />
            <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
            <TableColumnResizing resizingMode="nextColumn" columnWidths={localColumnsWidth} />
            <TableRowDetail contentComponent={RoleExpandedData} toggleCellComponent={CustomToggleCell} />
            <TableHeaderRow cellComponent={CustomHeaderCell} />
            <NewTableSelectionBar selectedRows={selectedRows} counterClass={styles.counterClass}>
              <RolesBulkOperations roles={roles} />
            </NewTableSelectionBar>
          </Grid>
        )
      )}
      {rowToDuplicate && (
        <CreateEditRoleModal
          isOpen={!!rowToDuplicate}
          editRole={rowToDuplicate}
          isDuplicate={true}
          onClose={() => {
            setRowToDuplicate(null);
          }}
        />
      )}
    </>
  );
};

RolesTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
};

export default RolesTable;
