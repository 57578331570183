import React, { useEffect, useMemo, useState } from 'react';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import classNames from 'classnames';
import moment from 'moment';
import { Routes } from 'shared/constants/routes.js';
import DateFilter from 'shared/modules/dateFilter.js';
import { PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { usePermissions } from 'app/contexts/PermissionsContext.jsx';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import mainStyles from 'app/containers/MainDashboard/mainDashboardCommon.module.scss';
import { NoPermission } from 'app/containers/MainDashboard/components/NoPermission.jsx';
import { getFirstDayOfYear } from 'app/containers/MainDashboard/utils.js';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import {
  getFiltersForDynamicFilterUnselectedValues,
  getInitialFiltersStateCopy,
} from 'recommendationsNew/components/heatMap/heatMapFilters/filterStates.js';
import useDynamicFilter from 'recommendationsNew/hooks/react-query/useDynamicFilter.js';
import { CLOSED_AND_DONE_RECS_DATES_FILTERS, FILTERS } from 'recommendationsNew/consts.js';
import useHeatMapSummary from 'recommendationsNew/hooks/react-query/useHeatMapSummary.js';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import useCommitmentData from 'app/containers/MainDashboard/hooks/react-query/useCommitmentData.js';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import SimplePieChart from 'shared/components/dashboardCharts/PieChart/SimplePieChart.jsx';
import { palette } from 'shared/constants/colorsConstants.js';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import ComingSoonTag from 'app/containers/MainDashboard/panels/MonitoringData/ComingSoonTag.jsx';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as EmptySavings } from 'app/containers/MainDashboard/img/empty-savings.svg';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import styles from './monitoringData.module.scss';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

const ChevronUpIcon = () => <GenerateIcon iconName={ICONS.chevronUp.name} className={styles.arrowIcon} />;
const SavingsData = () => {
  const { usersStore } = useRootStore();
  const { getCurrencyNumber } = useUserSettingsContext();
  const { updatePanelLoadingState } = useMainDashboardContext();
  const [showAttention, setShowAttention] = useState(false);
  const firstDayOfYear = getFirstDayOfYear(moment(DateFilter.getDate()));
  const isGcpAccount = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP;
  const isAzureAccount = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
  const { fetchCommitmentStatisticData } = useCommitmentData(!isGcpAccount);
  const { isHasPermission } = usePermissions();
  const hasPermission = isHasPermission(HierarchicalEntityCategory.Commitments, Action.View);
  const [recListFilters, setRecListFilters] = useState(null);
  const recommendationParams = {
    ...getInitialFiltersStateCopy(),
    [FILTERS.STATUS_FILTER.id]: 'actual_savings',
    [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: {
      [CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]: {
        from: firstDayOfYear,
        to: moment(DateFilter.getDate()).format('YYYY-MM-DD'),
      },
    },
  };
  const dynamicFilterHook = useDynamicFilter('cat_id');
  const { data: annualData, isLoading: isAnnualDataLoading } = dynamicFilterHook.fetchDynamicFilter(
    getFiltersForDynamicFilterUnselectedValues(recommendationParams),
  );
  const heatmapSummaryHook = useHeatMapSummary(recListFilters !== null);
  const { data: completeRecommendations, isLoading: isRecsLoading } =
    heatmapSummaryHook.fetchHeatMapSummary(recListFilters);

  const { data: spData, isLoading: spIsLoading } = fetchCommitmentStatisticData(
    isAzureAccount
      ? {}
      : {
          date: firstDayOfYear,
          commitmentType: 'sp',
        },
  );
  const { data: riData, isLoading: riIsLoading } = fetchCommitmentStatisticData({
    date: firstDayOfYear,
    commitmentType: 'ri',
  });

  useEffect(() => {
    if (!annualData?.page) {
      return;
    }
    setRecListFilters({
      ...recommendationParams,
      [FILTERS.CATEGORIES.id]: annualData?.page.filter((item) => item.label !== 'Commitments'),
    });
  }, [annualData]);

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.SAVINGS, isRecsLoading || isAnnualDataLoading || spIsLoading || riIsLoading);
  }, [isAnnualDataLoading, isRecsLoading, spIsLoading, riIsLoading]);

  const savingsSummaryData = useMemo(() => {
    if (!completeRecommendations) {
      return;
    }
    const totalCommitment = (+spData?.[0]?.spStats?.savings || 0) + +riData?.[0]?.totalSavingsServices?.totalCost;
    const totalRecommendations = completeRecommendations?.actualAnnualSavings;
    return {
      totalCommitment,
      totalRecommendations,
      chartData: [
        {
          name: 'recommendation',
          value: (totalRecommendations * 100) / (totalCommitment + totalRecommendations),
          color: palette.teal[400],
        },
        {
          name: 'commitment',
          value: (totalCommitment * 100) / (totalCommitment + totalRecommendations),
          color: '#F99BBB',
        },
      ],
    };
  }, [spData, riData, completeRecommendations]);

  const renderContent = () => {
    if (!hasPermission) {
      return <NoPermission isSmallPanel={true} />;
    }
    if (!savingsSummaryData?.totalCommitment && !savingsSummaryData?.totalRecommendations) {
      return (
        <div className={styles.emptyState}>
          <EmptySavings />
          <div className={styles.textWrapper}>
            <span className={styles.simpleText}>You have no savings</span>
            <span className={styles.simpleText}>
              <a className={styles.link} href={Routes.RECOMMENDATION_EXPLORER} target="_blank">
                Click here
              </a>
              to explore savings opportunities.
            </span>
          </div>
        </div>
      );
    }
    if (savingsSummaryData) {
      return (
        <div className={classNames(styles.dataWrapper)}>
          <div className={classNames(styles.savingsFlexData, styles.padding)}>
            <div className={styles.totalSavingsWrapper}>
              <span className={styles.simpleText}>Total Annual Savings:</span>
              <span className={styles.value}>
                {getCurrencyNumber(
                  (savingsSummaryData?.totalRecommendations || 0) + (savingsSummaryData?.totalCommitment || 0),
                )}
              </span>
            </div>
            <SimplePieChart
              data={savingsSummaryData?.chartData || []}
              height="60px"
              width="60px"
              dataKey="value"
              nameKey="name"
            />
            <div>
              <div className={styles.legendWrapper}>
                <svg width="10" height="10" className={styles.legendSymbol}>
                  <rect width="10" height="10" fill={palette.teal[400]} />
                </svg>
                <span className={styles.simpleText}>Recommendation based Savings</span>
                <InfoPopover
                  mode="outline"
                  className={{
                    icon: styles.infoIcon,
                    tooltip: tooltipStyles.whiteTooltip,
                    arrow: tooltipStyles.whiteArrow,
                  }}
                >
                  <span className={styles.tooltipText}>Commitments recommendation are excluded</span>
                </InfoPopover>
                <span className={styles.value}>
                  {getCurrencyNumber(savingsSummaryData?.totalRecommendations?.toLocaleString())}
                </span>
              </div>
              <div className={styles.legendWrapper}>
                <svg width="10" height="10" className={styles.legendSymbol}>
                  <rect width="10" height="10" fill="#F99BBB" />
                </svg>
                <span className={styles.simpleText}>Commitments</span>
                {isAzureAccount && (
                  <InfoPopover
                    mode="outline"
                    className={{
                      icon: styles.infoIcon,
                      tooltip: tooltipStyles.whiteTooltip,
                      arrow: tooltipStyles.whiteArrow,
                    }}
                  >
                    <span className={styles.tooltipText}>Refers to Reservations only, not Saving Plans.</span>
                  </InfoPopover>
                )}
                {isGcpAccount ? (
                  <ComingSoonTag className={styles.redComingSoon} />
                ) : (
                  <span className={styles.value}>{getCurrencyNumber(savingsSummaryData?.totalCommitment)}</span>
                )}
              </div>
            </div>
          </div>
          {isAzureAccount && (
            <div>
              {!showAttention ? (
                <Button
                  className={{ button: styles.attention }}
                  onClick={() => setShowAttention(!showAttention)}
                  text="Attention"
                  iconPlacement="right"
                  icon={ChevronUpIcon}
                />
              ) : (
                <div className={styles.attentionWrapper}>
                  <div className={styles.content}>
                    <span>
                      Make sure you provided the needed permissions in order to see the full Commitment Savings.
                    </span>
                    <a
                      className={styles.link}
                      href="https://cloudcost.anodot.com/hc/en-us/articles/11847396384924-RI-Utilization"
                      target="_blank"
                    >
                      Check Here
                    </a>
                  </div>
                  <Button
                    onClick={() => setShowAttention(!showAttention)}
                    text="X"
                    className={{ button: styles.close }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className={classNames(mainStyles.panelWrapper, styles.savingsWrapper)}>
      {isRecsLoading || isAnnualDataLoading || spIsLoading || riIsLoading ? (
        <Spinner size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <>
          <div className={styles.headerFlex}>
            <div className={styles.flex}>
              <div className={styles.header}>YTD Savings</div>
            </div>
          </div>
          {renderContent()}
        </>
      )}
    </div>
  );
};

export default SavingsData;
