import React from 'react';
import { DEFAULT_THEME } from 'app/containers/App/components/CustomThemeApplier';
import { useRootStore } from 'app/contexts/RootStoreContext';
import HolidayLogo from 'shared/components/HolidayThemedIcons/HolidayLogo';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const AppLogo = () => {
  const { usersStore } = useRootStore();
  const brandContext = useBrand();
  const currUserTheme = usersStore.getCurrDisplayedUserTheme();
  if (!currUserTheme) {
    return <div />;
  }
  if (currUserTheme === DEFAULT_THEME) {
    return usersStore.currentHoliday ? ( 
      <div className="h-100 d-flex align-items-center">
        <HolidayLogo holiday={usersStore.currentHoliday} />
      </div>
    ) : brandContext.logo.asComponent();
  }
  return <div id="header-logo-main" style={{ height: 34 }} />;
};

export default AppLogo;
