import React, { useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as MenuIcon } from 'shared/img/icons/three-dots-menu.svg';
import { ReactComponent as ModalHeaderAddIcon } from 'shared/img/icons/modal-header-add.svg';
import { ReactComponent as ModalHeaderEditIcon } from 'shared/img/icons/modal-header-edit.svg';
import { ReactComponent as ModalHeaderLockIcon } from 'shared/img/icons/modal-header-lock.svg';
import styles from './Modal.module.scss';
import Button from './Button.tsx';
import ButtonDropdown from './ButtonDropdown';
import InfoPopover from './InfoPopover';

export const MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  SIMPLE: 'simple',
  SIMPLE_NEW: 'simple_new',
  LOCK: 'lock',
} as const;

type ModalMode = typeof MODAL_MODES[keyof typeof MODAL_MODES];

interface ClassNameObject {
  modalHeader?: string;
  title?: string;
  container?: string;
  modal?: string;
}

interface CustomModalProps {
  open: boolean;
  onClose: (value: boolean) => void;
  onCloseClick?: () => void;
  onSecondPrimaryClick?: (val?: any) => void;
  onSecondCancelClick?: (val?: any) => void;
  closeOnOutside?: boolean;
  cancelTitle?: string;
  cancelHidden?: boolean;
  secondCancelTitle?: string;
  title?: React.ReactNode;
  onSave?: (e?: React.MouseEvent) => void | Promise<void>;
  className?: string | ClassNameObject;
  children?: React.ReactNode;
  saveDisabled?: boolean;
  saveWarning?: boolean;
  saveTitle?: string;
  menuContent?: React.ReactElement | React.ReactElement[];
  closeOnSave?: boolean;
  footerDisabled?: boolean;
  footerMessage?: string;
  overrideStyles?: React.CSSProperties;
  leftButton?: React.ReactNode;
  saveHidden?: boolean;
  automationId?: string;
  secondPrimaryTitle?: string;
  secondPrimaryDisabled?: boolean;
  headerMode?: ModalMode;
  headerIcon?: React.ReactNode;
  comment?: React.ReactNode;
  footerBorder?: boolean;
  isSecondPrimaryError?: boolean;
  isLoading?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  onCloseClick,
  onSecondCancelClick,
  closeOnOutside = true,
  cancelTitle = 'Cancel',
  cancelHidden = false,
  secondCancelTitle = '',
  title = null,
  onSave = () => Promise.resolve(),
  className = '',
  children,
  saveDisabled = false,
  saveWarning = false,
  saveTitle = 'Save',
  menuContent = null,
  closeOnSave = true,
  footerDisabled = false,
  footerMessage = '',
  overrideStyles = null,
  leftButton = null,
  saveHidden = false,
  automationId = 'modal',
  secondPrimaryTitle = null,
  onSecondPrimaryClick = null,
  secondPrimaryDisabled = false,
  headerMode = 'simple',
  headerIcon = null,
  comment = null,
  footerBorder = false,
  isSecondPrimaryError = true,
  isLoading = false,
}) => {
  const [cbLoading, setLoading] = useState<boolean>(false);

  const onClick = async (e: React.MouseEvent): Promise<void> => {
    try {
      setLoading(true);
      await onSave(e);
      setLoading(false);
      if (closeOnSave) {
        onClose(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setLoading(false);
    }
  };

  if (!open) {
    return null;
  }

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>): void => {
    const target = e.target as HTMLElement;
    if (
      closeOnOutside &&
      typeof target.className?.includes === 'function' &&
      target.className?.includes('capture-click-outside')
    ) {
      onClose(false);
    }
  };

  const renderHeader = (): React.ReactNode => {
    const infoIcon = comment ? (
      <InfoPopover isSimple>
        <div className={styles.comment}>{comment}</div>
      </InfoPopover>
    ) : (
      <div />
    );

    const classObject = typeof className === 'object' ? className : {};

    switch (headerMode) {
      case MODAL_MODES.ADD:
        return (
          <div className={classnames(styles.header, classObject.modalHeader)}>
            {headerIcon || <ModalHeaderAddIcon />}
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      case MODAL_MODES.EDIT:
        return (
          <div className={classnames(styles.header, classObject.modalHeader)}>
            <ModalHeaderEditIcon />
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      case MODAL_MODES.SIMPLE_NEW:
        return (
          <div className={classnames(styles.header, classObject.modalHeader)}>
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      case MODAL_MODES.LOCK:
        return (
          <div className={classnames(styles.header, classObject.modalHeader)}>
            <ModalHeaderLockIcon />
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      default:
        return title ? <h3 className={classnames(styles.title, classObject.title)}>{title}</h3> : null;
    }
  };

  const classObject = typeof className === 'object' ? className : {};

  return (
    <div
      className={`${classnames(styles.container, classObject.container)} capture-click-outside`}
      onMouseDown={handleClickOutside}
      data-automation-id={automationId}
    >
      <div
        className={classnames(styles.modal, classObject.modal || className)}
        style={overrideStyles || undefined}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Space') {
            e.stopPropagation();
          }
        }}
        tabIndex={0}
        role="dialog"
      >
        {menuContent && (
          <ButtonDropdown className={styles.menuIcon} icon={MenuIcon}>
            {menuContent}
          </ButtonDropdown>
        )}
        {renderHeader()}
        <div className={styles.content}>{children}</div>
        {!footerDisabled && (
          <div className={classnames(styles.footer, { [styles.footerBorder]: footerBorder })}>
            {footerMessage && <div className={styles.footerMessage}>{footerMessage}</div>}
            <div className={styles.buttons}>
              {leftButton && <div className={styles.leftMenu}>{leftButton}</div>}
              {!cancelHidden && (
                <>
                  <Button variant="secondary" onClick={() => onClose(false)} disabled={cbLoading}>
                    {cancelTitle}
                  </Button>
                  {secondCancelTitle && (
                    <Button variant="secondary" onClick={onSecondCancelClick || (() => {})} disabled={cbLoading}>
                      {secondCancelTitle}
                    </Button>
                  )}
                </>
              )}
              {secondPrimaryTitle && (
                <Button
                  variant={isSecondPrimaryError ? 'error' : 'primary'}
                  onClick={onSecondPrimaryClick || (() => {})}
                  disabled={secondPrimaryDisabled || cbLoading}
                >
                  {secondPrimaryTitle}
                </Button>
              )}
              {!saveHidden && (
                <Button
                  variant={saveWarning ? 'warning' : 'primary'}
                  onClick={onClick}
                  disabled={saveDisabled || cbLoading}
                  isLoading={isLoading || cbLoading}
                >
                  {saveTitle}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
