import { getDataWithCustomApikey } from 'shared/utils/apiUtil';
import CustomDashboardPanel from './customDashboardPanel';

export default class CustomDashboardActivePanel extends CustomDashboardPanel {
  constructor(
    panelParams,
    activePanelParams,
    activePanelDisplaySettings,
    defaultPanelIndex,
    rootStore,
    dashboardId,
    fromTemplate,
    filters,
  ) {
    super(panelParams);
    this.dashboardId = dashboardId;
    this.fromTemplate = fromTemplate;
    this.fetchFunc = activePanelParams.fetchFunc;
    this.fetchParamsPrepFunc = activePanelParams.fetchParamsPrepFunc;
    this.dataPrepFunc = activePanelParams.dataPrepFunc;
    this.chartType = activePanelParams.chartType;
    this.startDate = activePanelParams.startDate;
    this.endDate = activePanelParams.endDate;
    this.granularity = activePanelParams.granularity;
    this.displaySettings = this.setDisplaySettings(
      activePanelDisplaySettings,
      defaultPanelIndex,
      panelParams.uuid,
      panelParams.type,
    );
    this.rootStore = rootStore;
    this.wasteCostAllocationChecked = (panelParams.state || {}).wasteCostAllocationChecked;
    this.userKey = panelParams.userKey || this.rootStore.usersStore.currentDisplayedUserKey;
    this.accountKey = panelParams.accountKey || this.rootStore.usersStore.getCurrDisplayedAccount.accountKey;
    this.divisionId = panelParams.divisionId || this.rootStore.usersStore.getCurrDisplayedAccount.divisionId;
    this.accountId =
      !fromTemplate && panelParams.accountId
        ? panelParams.accountId
        : this.rootStore.usersStore.getCurrDisplayedAccount.accountId;
    this.cloudType = panelParams.cloudTypeId;
    this.panelData = [];
    this.filters = filters;
  }

  getRouteParams = () => {
    const { routeParams, state } = this;
    routeParams.granularity = this.granularity;
    routeParams.startDate = this.startDate;
    routeParams.endDate = this.endDate;
    routeParams.isPublicCost = routeParams.isPublicCost === undefined ? state?.isPublicCost : routeParams.isPublicCost;
    routeParams.wasteCostAllocationChecked = this.wasteCostAllocationChecked;
    routeParams.usageType = (state && state.SelectedUsageType) || 'Usage';
    return routeParams;
  };

  fetchData = async () => {
    try {
      const { rootStore, accountId, state } = this;
      const routeParams = this.getRouteParams();

      const userAccounts = rootStore.usersStore.getAllCurrDisplayedUserAccounts();
      const panelAccount = userAccounts.find((account) => account.accountId === accountId);
      const panelLastProcessDate = panelAccount && panelAccount.getLastProcessTime();

      const getDataFunc = () => {
        const prepArrParams = this.fetchParamsPrepFunc
          ? this.fetchParamsPrepFunc(
              routeParams,
              panelLastProcessDate,
              this.uuid,
              this.dashboardId,
              state,
              this.filters,
            )
          : Object.values(routeParams);
        return Array.isArray(prepArrParams) ? this.fetchFunc(...prepArrParams) : this.fetchFunc({ ...prepArrParams });
      };

      let data;
      const userAccId = rootStore.usersStore.getCurrDisplayedAccountId();
      if (rootStore.appStore.isKeyCloakManagement) {
        data = await this.fetchDataForNewUm(userAccId, accountId, rootStore, getDataFunc);
      } else {
        data = await this.fetchDataForOldUm(accountId, userAccId, rootStore, getDataFunc);
      }
      this.panelData = this.dataPrepFunc && data ? this.dataPrepFunc(data, routeParams) : [];
      return this.panelData;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return [];
    }
  };

  setDisplaySettings = (activePanelDisplaySettings, defaultPanelIndex, uuid, panelType) =>
    activePanelDisplaySettings || {
      uuid,
      span: panelType.includes('table') ? 2 : 1,
      order: defaultPanelIndex,
    };

  async fetchDataForOldUm(accountId, userAccId, rootStore, getDataFunc) {
    let data;
    const isPanelShowCurrentUser = accountId !== userAccId && !this.fromTemplate;
    if (isPanelShowCurrentUser) {
      const userDataFromPanelAccount = await rootStore.usersStore.getUserDataByAccount(this.accountId);
      data = await getDataWithCustomApikey(rootStore, userDataFromPanelAccount, getDataFunc);
      this.panelAccountParams = userDataFromPanelAccount;
    } else {
      data = await getDataFunc();
    }
    return data;
  }

  async fetchDataForNewUm(userAccId, accountId, rootStore, getDataFunc) {
    const customApiKeyParams = {
      accountKey: this.fromTemplate ? userAccId : accountId,
      divisionId: this.divisionId,
      userKey: this.userKey,
    };
    if (accountId !== userAccId && !this.fromTemplate) {
      this.panelAccountParams = {
        accountName: rootStore.usersStore.getAccountByAccountId(accountId)?.accountName,
      };
    }
    return getDataWithCustomApikey(rootStore, customApiKeyParams, getDataFunc);
  }

  get panelOrder() {
    return this.displaySettings.order;
  }

  get panelSpan() {
    return this.displaySettings.span;
  }
}
