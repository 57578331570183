import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, DataTypeProvider } from '@devexpress/dx-react-grid';
import moment from 'moment';
import { filtersFields, groupByOptions, PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import {
  aggregateCostsByGroup,
  compareFormatterNumber,
  getFirstDayOfMonth,
  getSearchParamsPanel,
} from 'app/containers/MainDashboard/utils.js';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable.jsx';
import DateFilter from 'shared/modules/dateFilter.js';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import NavigateIcon from 'app/containers/MainDashboard/components/NavigateIcon.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import FilterField from 'app/containers/MainDashboard/components/FilterField.jsx';
import { GroupByLovToAttributes } from 'usage/constants/costAndUsageConstants.js';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants.js';
import Button from 'shared/components/andtComponents/Button';
import LabelCoordinator from 'shared/modules/labelCoordinator.js';
import SaveDashboardPanel from 'app/containers/MainDashboard/components/SaveDashboardPanel.jsx';
import { PANEL_COMPONENT_TYPES } from 'usage/containers/CustomDashboard/constants/customDashboardConstants.js';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import mainStyles from '../mainDashboardCommon.module.scss';
import styles from './mtdTop10.module.scss';

const modeSwitch = {
  daily: { id: 'daily', text: 'Daily' },
  mtd: { id: 'monthly', text: 'MTD' },
};

const getColumns = (getCurrencyNumber, groupByHeader, isDailyMode, previousDates, currentDates) => {
  return [
    { name: 'groupBy', title: groupByHeader },
    {
      name: 'previousMonthCost',
      title: `Previous ${isDailyMode ? 'Day' : 'MTD'} Costs`,
      getCellValue: (row) => `${row.previousMonthCost ? getCurrencyNumber(row.previousMonthCost) : ''}`,
      subTitle: previousDates,
    },
    {
      name: 'mtdCost',
      title: `${isDailyMode ? modeSwitch.daily.text : modeSwitch.mtd.text} Costs`,
      getCellValue: (row) => `${row.mtdCost ? getCurrencyNumber(row.mtdCost) : ''}`,
      subTitle: currentDates,
    },
    {
      name: 'change',
      title: 'Change (%)',
      getCellValue: (row) =>
        row.previousMonthCost ? ((row.previousMonthCost - row.mtdCost) * 100) / row.previousMonthCost : 0,
    },
  ];
};

const columnExtensions = [
  { columnName: 'groupBy', width: '220px' },
  { columnName: 'previousMonthCost', compare: compareFormatterNumber, width: '150px' },
  { columnName: 'mtdCost', compare: compareFormatterNumber, width: '150px' },
  { columnName: 'change', width: '100px' },
];

const PercentColumnFormatter = ({ value }) => <PercentTag percent={value * -1} />;

PercentColumnFormatter.propTypes = {
  value: PropTypes.number.isRequired,
};

const HeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;
  return (
    <TableHeaderRow.Cell column={column} className={styles.stickyHeader} {...restProps}>
      <div className={styles.columnHeaderWrapper}>
        <span className={styles.columnHeader}>
          {children}
          {column.info && (
            <InfoPopover
              isSimple
              mode="outline"
              className={{
                icon: styles.infoIcon,
                tooltip: tooltipStyles.whiteTooltip,
                arrow: tooltipStyles.whiteArrow,
              }}
            >
              <span className={styles.tooltipText}>{column.info}</span>
            </InfoPopover>
          )}
        </span>
        {column.subTitle && <span className={styles.subTitle}>{column.subTitle}</span>}
      </div>
    </TableHeaderRow.Cell>
  );
};
HeaderCell.propTypes = {
  column: PropTypes.shape({
    title: PropTypes.string.isRequired,
    info: PropTypes.string,
    subTitle: PropTypes.string,
  }).isRequired,
};

const getGroupOptions = (currentCloudType) => {
  const groups = groupByOptions[currentCloudType]; //GCPCommonFieldLabels
  return groups.map((group) => ({
    value: GroupByLovToAttributes.get(group),
    label:
      currentCloudType === CLOUD_TYPE_IDS.GCP && GCPCommonFieldLabels.get(group)
        ? GCPCommonFieldLabels.get(group)
        : LabelCoordinator.getFieldLabel(group),
  }));
};
const MtdTop10 = ({ isFullWidth = false, isDailyMode = false, hideSaveDashboard = false, exsitGroupBy }) => {
  const { updatePanelLoadingState, dateFromLastMonth, getDynamicFilters } = useMainDashboardContext();
  const { NewTableWrapper } = useTable();
  const [selectedMode, setSelectedMode] = useState(isDailyMode ? modeSwitch.daily.id : modeSwitch.mtd.id);
  const cueHook = useCueData();
  const { appStore, usersStore } = useRootStore();
  const { getCurrencyNumber } = useUserSettingsContext();
  const options = getGroupOptions(usersStore.currDispUserCloudAccountType);
  const [groupBy, setGroupBy] = useState(exsitGroupBy || [{ value: 'service', label: 'Service' }]);

  const mtdDatesParams = {
    start:
      selectedMode === modeSwitch.mtd.id
        ? getFirstDayOfMonth(moment(DateFilter.getDate()))
        : moment(DateFilter.getDate()).subtract(1, 'days').format('YYYY-MM-DD'),
    end:
      selectedMode === modeSwitch.mtd.id
        ? moment(DateFilter.getDate()).format('YYYY-MM-DD')
        : moment(DateFilter.getDate()).subtract(1, 'days').format('YYYY-MM-DD'),
  };
  const previousDatesParams = {
    start:
      selectedMode === modeSwitch.mtd.id
        ? getFirstDayOfMonth(dateFromLastMonth)
        : moment(DateFilter.getDate()).subtract(2, 'days').format('YYYY-MM-DD'),
    end:
      selectedMode === modeSwitch.mtd.id
        ? dateFromLastMonth.format('YYYY-MM-DD')
        : moment(DateFilter.getDate()).subtract(2, 'days').format('YYYY-MM-DD'),
  };
  const { data: mtdData, isLoading: isMtdLoading } = cueHook.fetchCueData({
    ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
    start: mtdDatesParams.start,
    end: mtdDatesParams.end,
    groupBy: groupBy?.[0]?.value,
    isPpApplied: appStore.isPpApplied,
    periodGranLevel: selectedMode === modeSwitch.daily.id ? 'day' : 'month',
    ...getDynamicFilters(),
  });

  const { data: previousMtdData, isLoading: isPreviousMtdLoading } = cueHook.fetchCueData({
    ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
    start: previousDatesParams.start,
    end: previousDatesParams.end,
    groupBy: groupBy?.[0]?.value,
    isPpApplied: appStore.isPpApplied,
    periodGranLevel: selectedMode === modeSwitch.daily.id ? 'day' : 'month',
    ...getDynamicFilters(),
  });

  const navigateCueParams = {
    ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
    groupBy: groupBy?.[0]?.value,
    periodGranLevel: 'day',
    start: previousDatesParams.start,
    end: mtdDatesParams.end,
    ...getDynamicFilters(),
  };
  const isLoading = isPreviousMtdLoading || isMtdLoading;
  const [sorting, setSorting] = useState([{ columnName: 'mtdCost', direction: 'desc' }]);

  const totalCostData = useMemo(() => {
    if (!mtdData || !previousMtdData) {
      return null;
    }

    const mtdTotal = aggregateCostsByGroup(mtdData, groupBy?.[0]?.value === 'linkedaccid');
    const previousTotal = aggregateCostsByGroup(previousMtdData, groupBy?.[0]?.value === 'linkedaccid');

    return { mtdTotalData: mtdTotal, previousTotalData: previousTotal };
  }, [previousMtdData, mtdData, groupBy]);

  const panelData = useMemo(() => {
    if (!totalCostData) {
      return [];
    }
    if (totalCostData.mtdTotalData.size === 0) {
      const data = totalCostData.previousTotalData.entries();
      return [...data].map(([groupBy, { totalCost, linkedAccountName }]) => ({
        groupBy: linkedAccountName || groupBy,
        previousMonthCost: totalCost || 0,
        mtdCost: '',
      }));
    }
    const sortedMtdTotalData = [...totalCostData.mtdTotalData.entries()]
      .sort(([, a], [, b]) => b.totalCost - a.totalCost)
      .slice(0, 10);

    return sortedMtdTotalData.map(([groupBy, { totalCost, linkedAccountName }]) => {
      const previousMonthCost = totalCostData.previousTotalData.get(groupBy) || 0;
      return {
        groupBy: linkedAccountName || groupBy,
        previousMonthCost: previousMonthCost?.totalCost || 0,
        mtdCost: totalCost || 0,
      };
    });
  }, [totalCostData]);

  const getRangeDates = (dateParams) => {
    if (selectedMode === modeSwitch.mtd.id) {
      const formattedStartDate = moment(dateParams.start).format('MMM D');
      const formattedEndDate = moment(dateParams.end).format('D, YYYY');
      return `${formattedStartDate}-${formattedEndDate}`;
    }
    return moment(dateParams.start).format('MMM D, YYYY');
  };
  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.MTD_TOP_10, isLoading);
  }, [isLoading]);

  return (
    <div
      className={classNames(
        mainStyles.panelWrapper,
        !isFullWidth && usersStore.isCurrentAccountSingle ? mainStyles.halfWidthPanelWrapper : mainStyles.fullWidth,
      )}
    >
      <div className={mainStyles.headerRow}>
        <div className={styles.headerWrapper}>
          <span className={mainStyles.header}>{PANELS[PANELS_TYPES.MTD_TOP_10].title}</span>
          <FilterField
            filterType={filtersFields.by}
            filterDataLength={0}
            onSelectMultiButtonClick={() => {}}
            selectedOptions={groupBy}
            handleSelectionChange={(options) => setGroupBy([options])}
            options={options}
            height={260}
            width={215}
          />
        </div>
        <div className={styles.headerWrapper}>
          <div className={styles.modeTypeWrapper}>
            <Button
              key="monthly"
              text="MTD"
              className={{ button: classNames(styles.mode, selectedMode === modeSwitch.mtd.id && styles.selected) }}
              onClick={() => setSelectedMode(modeSwitch.mtd.id)}
            />
            <Button
              key={modeSwitch.daily.id}
              text={modeSwitch.daily.text}
              className={{ button: classNames(styles.mode, selectedMode === modeSwitch.daily.id && styles.selected) }}
              onClick={() => setSelectedMode(modeSwitch.daily.id)}
            />
          </div>
          <NavigateIcon
            getSearchParams={() => getSearchParamsPanel(navigateCueParams, appStore.isPpApplied)}
            id={PANELS_TYPES.MTD_TOP_10}
          />
          {!hideSaveDashboard && (
            <SaveDashboardPanel
              groupBy={groupBy}
              panelType={
                selectedMode === modeSwitch.mtd.id
                  ? PANEL_COMPONENT_TYPES.MONTH_TO_DATE_TOP_5
                  : PANEL_COMPONENT_TYPES.DAILY_CHANGE_TOP_5_COSTS
              }
            />
          )}
        </div>
      </div>
      {isLoading ? (
        <Spinner className={mainStyles.spinner} size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <NewTableWrapper>
          <div className="sub-table">
            <Grid
              rows={panelData}
              columns={getColumns(
                getCurrencyNumber,
                groupBy?.[0]?.label,
                selectedMode === modeSwitch.daily.id,
                getRangeDates(previousDatesParams),
                getRangeDates(mtdDatesParams),
              )}
            >
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting columnExtensions={columnExtensions} />
              <DataTypeProvider for={['change']} formatterComponent={PercentColumnFormatter} />
              <TableWrapper virtual height="280px" columnExtensions={columnExtensions} />
              <TableHeaderRow showSortingControls cellComponent={HeaderCell} />
            </Grid>
          </div>
        </NewTableWrapper>
      )}
    </div>
  );
};

MtdTop10.propTypes = {
  isFullWidth: PropTypes.bool,
  isDailyMode: PropTypes.bool,
  hideSaveDashboard: PropTypes.bool,
  exsitGroupBy: PropTypes.object,
};
export default MtdTop10;
