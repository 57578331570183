import React, { useEffect, useMemo, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import useUserRecAction from 'recommendationsNew/hooks/react-query/useUserRecAction';
import { palette } from 'shared/constants/colorsConstants';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { ACTION_STATUS, ACTION_TYPE } from 'recommendationsNew/consts';
import classes from './recommendationsList.module.scss';
import RecommendationExcluded from './recommendationExcluded';
import RecommendationLabel from './recommendationLabel';
import { usePermissions } from '../../../app/contexts/PermissionsContext';

const BulkOperationsRow = ({ selectedRecs, exportCsv }) => {
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [recIds, setRecIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isHasPermission } = usePermissions();
  const toggleMenu = (e, closeMenu = false) => {
    e.stopPropagation();
    setAnchorEl(closeMenu ? null : e.currentTarget);
  };
  const [actionType, setActionType] = useState(null);

  const recActionHook = useUserRecAction();
  const { bulkUserAction } = recActionHook;

  const { mutateAsync: bulkActionAsync } = bulkUserAction();

  const renderUserStatus = () => {
    const excludedRows = selectedRecs.filter((row) => row.userStatus?.status === ACTION_STATUS.excluded);
    const doneRows = selectedRecs.filter((row) => row.userStatus?.status === ACTION_STATUS.done);
    const emptyRows = selectedRecs.filter((row) => !row.userStatus);

    const getUnmarkText = () => {
      if (!excludedRows.length) {
        return 'Done';
      }
      if (!doneRows.length) {
        return 'Exclude';
      }
      return 'Done/Excluded';
    };

    const unMarkAction = () => {
      setAnchorEl(null);
      if (doneRows.length) {
        bulkActionAsync({ action: 'undone', recIds: doneRows.map((row) => row.recId) });
        setActionType('undone');
      }
      if (excludedRows.length) {
        bulkActionAsync({ action: 'include', recIds: excludedRows.map((row) => row.recId) });
        setActionType('include');
      }
    };
    return (
      <>
        {doneRows.length < selectedRecs.length && (
          <MenuItem
            classes={{ root: classes.menuItem }}
            onClick={() => {
              setAnchorEl(null);
              bulkActionAsync({ action: 'done', recIds });
              setActionType('done');
            }}
          >
            <div automation-id="bulk-rec-done">
              <GenerateIcon iconName={ICONS.clipboardCheck.name} className={classes.iconPadding} />
              <span className={classes.menuItem}>
                Mark as <b>Done</b>
              </span>
            </div>
          </MenuItem>
        )}
        {excludedRows.length < selectedRecs.length && (
          <MenuItem
            classes={{ root: classes.menuItem }}
            onClick={() => {
              setAnchorEl(null);
              setShowExcludeModal(true);
            }}
          >
            <div className={classes.secondItem} automation-id="bulk-rec-exclude">
              <GenerateIcon iconName={ICONS.excluded.name} />
              <span className={classes.menuItem}>
                Mark as <b>Excluded</b>
              </span>
            </div>
          </MenuItem>
        )}
        {emptyRows.length < selectedRecs.length && (
          <MenuItem classes={{ root: classes.menuItem }} onClick={unMarkAction}>
            <div className={classes.secondItem} automation-id="bulk-rec-exclude">
              <GenerateIcon iconName={ICONS.clipboard.name} />
              <span className={classes.menuItem}>
                UnMark as <b>{getUnmarkText()}</b>
              </span>
            </div>
          </MenuItem>
        )}
      </>
    );
  };
  const renderStar = () => (
    <>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => {
          setAnchorEl(null);
          bulkActionAsync({ action: 'star', recIds });
          setActionType('star');
        }}
        automation-id="bulk-rec-star"
      >
        <div>
          <GenerateIcon iconName={ICONS.star.name} className={classes.iconPadding} />
          <span className={classes.menuItem}>Add Star</span>
        </div>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => {
          setAnchorEl(null);
          bulkActionAsync({ action: 'unstar', recIds });
          setActionType('unstar');
        }}
        automation-id="bulk-rec-unstar"
      >
        <div>
          <GenerateIcon iconName={ICONS.starHollow.name} className={classes.iconPadding} />
          <span className={classes.menuItem}>Remove Star</span>
        </div>
      </MenuItem>
    </>
  );

  const renderExport = () => (
    <>
      <MenuItem classes={{ root: classes.menuItem }} onClick={() => setAnchorEl(null)}>
        <CustomCSVDownload
          fetchData={exportCsv}
          automationId="export-recommendations"
          showDownloadIcon
          isLoading={false}
          filesNumber={1}
          isSecondary
          hideText
          style={{
            background: 'none',
          }}
          automation-id="bulk-rec-export"
        >
          CSV
        </CustomCSVDownload>
      </MenuItem>
      {/* <MenuItem classes={{ root: classes.menuItem }} onClick={() => {}}> */}
      {/*  <div> */}
      {/*    <GenerateIcon iconName={ICONS.download.name} className={classes.iconPadding} /> */}
      {/*    <span className={classes.menuItem}>Terraform Format</span> */}
      {/*  </div> */}
      {/* </MenuItem> */}
    </>
  );

  const getLabels = () => {
    const labels = {};
    selectedRecs.forEach((rec) => {
      if (!rec.labels) {
        return;
      }
      rec.labels.split(',').forEach((label) => {
        labels[label] = [...(labels[label] || []), rec.recId];
      });
    });
    return Object.keys(labels).map((key) => ({ label: key, recIds: labels[key] }));
  };
  const labelsOptions = useMemo(() => getLabels(), [selectedRecs]);

  useEffect(() => {
    setRecIds([...(selectedRecs || [])].map((r) => r.recId));
  }, [selectedRecs]);
  return (
    <div className={classes.bulkOperationsWrapper}>
      <Button
        isTextButton
        overrideStyles={{ color: palette.gray[500] }}
        text="User Status"
        disabled={!isHasPermission(OrganizationEntityCategory.OrganizationManagement, Action.Update)}
        icon={() => <GenerateIcon iconName={ICONS.clipboardQuestion.name} />}
        onClick={(e) => {
          toggleMenu(e);
          setActionType('status');
        }}
        automationId="bulk-rec-status"
      />
      <Button
        isTextButton
        overrideStyles={{ color: palette.gray[500] }}
        text="Star"
        icon={() => <GenerateIcon iconName={ICONS.star.name} />}
        disabled={!isHasPermission(OrganizationEntityCategory.OrganizationManagement, Action.Update)}
        onClick={(e) => {
          toggleMenu(e);
          setActionType('star');
        }}
        automationId="bulk-rec-star"
      />
      {/* <Button */}
      {/*  isTextButton */}
      {/*  text="Assignee" */}
      {/*  icon={() => <GenerateIcon iconName={ICONS.clipboardUser.name} />} */}
      {/*  onClick={(e) => { */}
      {/*    toggleMenu(e); */}
      {/*    setActionType('assignee'); */}
      {/*  }} */}
      {/* /> */}
      {/* <Button */}
      {/*  isTextButton */}
      {/*  text="Read/Unread" */}
      {/*  icon={() => <GenerateIcon iconName={ICONS.eye.name} />} */}
      {/*  onClick={(e) => { */}
      {/*    toggleMenu(e); */}
      {/*    setActionType('read'); */}
      {/*  }} */}
      {/* /> */}
      <Button
        isTextButton
        text="Export"
        overrideStyles={{ color: palette.gray[500] }}
        icon={() => <GenerateIcon iconName={ICONS.download.name} />}
        onClick={(e) => {
          toggleMenu(e);
          setActionType('export');
        }}
        automationId="bulk-rec-export"
      />
      <Button
        isTextButton
        text="Add/Remove Label"
        overrideStyles={{ color: palette.gray[500] }}
        disabled={!isHasPermission(OrganizationEntityCategory.OrganizationManagement, Action.Update)}
        icon={() => <GenerateIcon iconName={ICONS.hashtag.name} />}
        onClick={() => {
          setActionType('label');
          setShowLabelModal(true);
        }}
        automationId="bulk-rec-label"
      />
      <Menu
        classes={{ list: classes.menu }}
        style={{ marginTop: '-20px' }}
        id="simple-menu"
        anchorEl={anchorEl}
        disabled={!isHasPermission(OrganizationEntityCategory.OrganizationManagement, Action.Update)}
        open={Boolean(anchorEl)}
        onClose={(e) => toggleMenu(e, true)}
      >
        {actionType === 'status' && renderUserStatus()}
        {actionType === 'star' && renderStar()}
        {actionType === 'export' && renderExport()}
      </Menu>
      {showExcludeModal && (
        <RecommendationExcluded
          recs={selectedRecs}
          closeModal={(obj) => {
            if (obj) {
              bulkActionAsync({
                action: ACTION_TYPE.exclude,
                recIds,
                actionParams: obj,
              });
              setActionType(ACTION_TYPE.exclude);
            }
            setShowExcludeModal(false);
          }}
        />
      )}
      {showLabelModal && (
        <RecommendationLabel
          closeModal={() => setShowLabelModal(false)}
          recLabels={labelsOptions}
          recIds={recIds}
          isFromBulk
          saveLabels={(addLabels, removeLabels) => {
            if (!addLabels.length && !removeLabels.length) {
              return;
            }
            bulkActionAsync({
              action: 'label',
              recIds,
              actionParams: { data: { label_add: addLabels.join(','), label_delete: removeLabels.join(',') } },
            });
            setActionType('label');
          }}
        />
      )}
    </div>
  );
};

export default BulkOperationsRow;
