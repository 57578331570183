export const ONBOARDING_FIELDS = {
  SCREEN: 'screen',
  IS_MSP: 'isMSP',
  ACCOUNT_TYPE: 'accountType',
  ACCOUNT_ID: 'accountId',
  TENANT_NAME: 'tenantName',
  TENANT_SCHEMA_NAME: 'tenantScheme',
  CLOUD_TYPE_ID: 'cloudTypeId',
  ACCOUNT_NAME: 'accountName',
  VALIDATION_STARTED: 'validationStarted',
  VALIDATION_START_TRIGGER: 'validationStartTrigger',
  ACCOUNT_SETUP_SHOULD_START: 'accountSetupShouldStart',
  ONBOARDING_SUCCESS: 'onboardingSuccess',
  INVOICE_SUCCESS: 'invoiceSuccess',
  STARTED: 'started',
  IS_BIGQUERY: 'isBigquery',
  RESELLER_CUSTOMER: 'resellerCustomer',
  EXISTING_CUSTOMER_SELECTED: 'existingCustomerSelected',
  RESELLER_CUSTOMER_ID: 'resellerCustomerId',
  ONBOARDING_TYPE: 'onboardingType',
  USER_KEY: 'userKey',
  ACCOUNT_KEY: 'accountKey',
  AUTO_ASSIGN_LINKED_ACCOUNTS: 'autoAssignLinkedAccounts',
  IS_CUSTOMER_SELF_MANAGED: 'isCustomerSelfManaged',
  CUSTOMER_DOMAIN: 'resellerCustomerDomain',
  CUSTOMER_ADMIN_EMAIL: 'resellerCustomerEmail',
  EXCLUDED_LINKED_ACCOUNT_MATCH: 'excludedLinkedAccountMatch',
};

export const ONBOARDING_TYPES = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
};
