import PropTypes from 'prop-types';
import React from 'react';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { Routes } from 'shared/constants/routes';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { ReactComponent as HourlyCommitment } from '../../../img/hourlyCommitment.svg';
import checkFeatureFlag from 'shared/utils/featureFlagUtil.js';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants.js';
import classes from './recommendedHourlyCommitment.module.scss';

const RecommendedHourlyCommitment = ({ hourlyCommitment }) => {
  const { usersStore } = useRootStore();
  const { currencySymbol } = useUserSettingsContext();

  const supportNewSPPage = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.SP_ANALYZER_NEW);
  if (isNaN(hourlyCommitment)) {
    return null;
  }

  return (
    <div className={`${classes.container} ${usersStore.isCurrentUserSharedReCustomer ? classes.smallContainer : null}`}>
      <div className={classes.svgContainer}>
        <HourlyCommitment />
      </div>
      <div className={classes.hourlyCommitmentContainer}>
        <div className={classes.text}>Recommended Hourly Commitments ({currencySymbol})</div>
        <div className={classes.value}>{hourlyCommitment}</div>
      </div>
      {!usersStore.isCurrentUserSharedReCustomer && (
        <div className={classes.spAnalyzerContainer}>
          <div className={classes.text}>Continue your investigation using SP Analyzer</div>
          <div className={classes.continueAnalysis}>
            <GenerateIcon iconName={ICONS.magnifyingGlassDollar.name} />
            <a
              href={supportNewSPPage ? Routes.SAVINGS_PLANS_ANALYZER_NEW : Routes.SAVINGS_PLANS_ANALYZER}
              target="_blank"
              rel="noreferrer"
            >
              Continue Analysis
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

RecommendedHourlyCommitment.propTypes = {
  hourlyCommitment: PropTypes.number.isRequired,
};

export default RecommendedHourlyCommitment;
