import React, { HTMLProps, ReactEventHandler } from 'react';
import styles from 'shared/components/tableHeader/tableHeader.module.scss';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import classNames from 'classnames';

export interface TableLikeHeaderProps extends HTMLProps<HTMLDivElement> {
  isExpanded: boolean;
  title?: string;

  onExpandClick(): void;

  totalNumber: number;

  onCreate?(e: ReactEventHandler): void;
}

export const TableLikeHeader: React.FC<TableLikeHeaderProps> = ({
  totalNumber,
  onExpandClick,
  isExpanded,
  children,
  title = '',
  onCreate,
  className,
  ...divProps
}) => (
  <div {...divProps} className={classNames(styles.header, className)}>
    <div className={styles.title}>
      <Button
        automationId="expand-rows"
        text=""
        isSecondary
        icon={() => <GenerateIcon iconName={isExpanded ? ICONS.expand.name : ICONS.collapse.name} />}
        onClick={onExpandClick}
      />
      <div>
        <span className={styles.titleNumber}>{totalNumber}</span>
        <span>{title}</span>
      </div>
      <div className={classNames(styles.filterWrapper, 'align-items-center')}>{children}</div>
    </div>
    <div className={styles.rightMenu}>
      {onCreate && (
        <Button
          text="Create Alert Rule"
          onClick={() => onCreate}
          icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
          automationId="create-alert-rule"
        />
      )}
    </div>
  </div>
);

export default TableLikeHeader;
