import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { addClusterDefaultState } from '../config.ts';
import { validateAddClusterForm } from './utils.ts';
import styles from '../K8sOnboarding.module.scss';
import CustomModal from 'shared/components/andtComponents/Modal';
import { AddedCluster } from '../types.ts';
import AddClusterForm from './AddClusterForm.tsx';

type Props = {
  activeCluster?: AddedCluster | null;
  updateCluster: (u: AddedCluster, c?: AddedCluster | null) => void;
  onClose(): void;
};

const AddClusterModal: React.FC<Props> = ({ activeCluster, onClose, updateCluster }) => {
  const methods = useForm({
    defaultValues: activeCluster || addClusterDefaultState,
    resolver: async (formState) => {
      const errors = validateAddClusterForm(formState);
      return {
        values: Object.keys(errors).length === 0 ? formState : {},
        errors,
      };
    },
    shouldFocusError: true,
  });

  const onSave = useCallback(() => {
    const clusterUpdates = methods.getValues();
    updateCluster(clusterUpdates, activeCluster);
    methods.reset();
  }, [activeCluster]);

  return (
    <CustomModal
      open={true}
      onClose={onClose}
      onCloseClick={onClose}
      headerMode={'add'}
      title={`${!activeCluster ? 'Add' : 'Edit'} Cluster`}
      saveTitle="Save"
      onSave={onSave}
      saveDisabled={Object.keys(methods?.formState?.errors || {}).length > 0}
      closeOnSave
      className={styles.bigModal}
      automationId={`addClusterModal`}
    >
      <FormProvider {...methods}>
        <AddClusterForm />
      </FormProvider>
    </CustomModal>
  );
};

export default AddClusterModal;
