import ClusterInformationStep from './ClusterInformation1Step.tsx';
import ValidationStep from './ValidationStep.tsx';
import YamlStep from './YamlStep.tsx';
import InstallationStep from './InstallationStep.tsx';

export const K8S_ONBOARDING_STEPS_CONFIG = [
  {
    title: 'Enter Cluster Configuration Details',
    titleShort: 'Cluster Information',
    component: ClusterInformationStep,
  },
  {
    title: 'Configuration validation of existing Prometheus server',
    titleShort: 'Validation',
    component: ValidationStep,
  },
  {
    title: 'Configure YAML File with Access Keys',
    titleShort: 'YAML files',
    component: YamlStep,
  },
  {
    title: 'Installation Commands',
    titleShort: 'Installation Commands',
    component: InstallationStep,
  },
];

export enum AddClusterFields {
  linkedAccountId = 'LINKED_ACCOUNT_ID',
  clusterName = 'CLUSTER_NAME',
  agentNamespace = 'AGENT_NAMESPACE',
  prometheusUrl = 'PROMETHEUS_URL',
  prometheusConnectingCreds_block = 'prometheusConnectingCreds',
  prometheusConnectingCreds_username = 'USERNAME',
  prometheusConnectingCreds_password = 'PASSWORD',
  httpHeaders_block = 'REQUEST_HEADERS_BLOCK', //'{"X-Scope-OrgID": "YOUR-TENANT-NAME"}'
  httpHeaders = 'REQUEST_HEADERS', //'{"X-Scope-OrgID": "YOUR-TENANT-NAME"}'
  uniqueJobs_block = 'uniqueJobs', // KUBE_STATE_METRICS_JOB_NAME: 'kube-state-metrics', KUBELET_JOB_NAME: 'kubelet'
  kubeStateMetricsJobName = 'KUBE_STATE_METRICS_JOB_NAME', // KUBE_STATE_METRICS_JOB_NAME: 'kube-state-metrics', KUBELET_JOB_NAME: 'kubelet'
  kubeletJobName = 'KUBELET_JOB_NAME', // KUBE_STATE_METRICS_JOB_NAME: 'kube-state-metrics', KUBELET_JOB_NAME: 'kubelet'
  otherMetrics_block = 'METRIC_CONDITION', //'cluster="<INSERT CLUSTER NAME HERE>"'
  otherMetrics_key = 'METRIC_CONDITION_KEY', //'cluster="<INSERT CLUSTER NAME HERE>"'
  otherMetrics_value = 'METRIC_CONDITION_VALUE', //'cluster="<INSERT CLUSTER NAME HERE>"'
}

export const addClusterDefaultState = {
  id: '',
  [AddClusterFields.linkedAccountId]: '',
  [AddClusterFields.clusterName]: '',
  [AddClusterFields.agentNamespace]: '',
  [AddClusterFields.prometheusUrl]: '',
  [AddClusterFields.prometheusConnectingCreds_block]: false,
  [AddClusterFields.prometheusConnectingCreds_username]: '',
  [AddClusterFields.prometheusConnectingCreds_password]: '',
  [AddClusterFields.httpHeaders_block]: false,
  [AddClusterFields.httpHeaders]: '[]',
  [AddClusterFields.uniqueJobs_block]: false,
  [AddClusterFields.kubeStateMetricsJobName]: 'kube-state-metrics',
  [AddClusterFields.kubeletJobName]: 'kubelet',
  [AddClusterFields.otherMetrics_block]: false,
  [AddClusterFields.otherMetrics_key]: 'cluster',
  [AddClusterFields.otherMetrics_value]: '',
}

export const addClusterLabels = {
  [AddClusterFields.linkedAccountId]: {label: "Linked Account ID", error: "is required"},
  [AddClusterFields.clusterName]: {label: 'Cluster Name', error: "Cluster Name is required"},
  [AddClusterFields.agentNamespace]: {label: 'Agent Namespace', error: "Agent Namespace is required"},
  [AddClusterFields.prometheusUrl]: {label: 'Prometheus URL', error: "Prometheus URL is required"},
}

