import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import Pagination from 'shared/components/andtComponents/Pagination';
import PropTypes from 'prop-types';
import { Search } from '../components/Search';
import RolesIcon from './RolesIcon';
import { DEFAULT_PAGE_SIZE } from '../consts';
import { useRolesContext } from './contexts/rolesContext';
import { fetchRoles } from 'users/new-user-management/hooks/reactQuery/apiRoles';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import RoleActions from 'users/containers/Organization/Roles/RoleActions';

import styles from './RolesHeader.module.scss';

const RolesHeader = ({ currentPage, isNextPageLoading, pageChanged }) => {
  const { isAllExpanded, setRoles, excludeRoles, setIsAllExpanded, setExcludeRoles, search, setSearch, totalRoles } =
    useRolesContext();

  const fetchExportData = async () => {
    const data = await fetchRoles({ search });
    if (data?.roles) {
      return [{ data: data?.roles, filename: 'roles.csv' }];
    }
  };

  const totalPages = totalRoles >= 0 ? Math.ceil(totalRoles / DEFAULT_PAGE_SIZE) : 0;

  const onPageChange = (newPage) => {
    pageChanged(newPage - currentPage);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <Button
          automationId="expand-rows"
          text=""
          isSecondary
          icon={() => <GenerateIcon iconName={isAllExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
          onClick={() => {
            setIsAllExpanded(!isAllExpanded);
          }}
        />
        <div className={styles.header}>
          <RolesIcon />
          <div className={styles.title}>
            Roles <span className={styles.count}>({totalRoles})</span>
          </div>
        </div>
        <Checkbox
          isChecked={!excludeRoles}
          onChange={() => {
            setExcludeRoles(!excludeRoles);
            setRoles(null);
          }}
          primary
          text="Built-in Roles"
        />
      </div>
      <div className={styles.headerRight}>
        <Search search={search} setSearch={setSearch} isDebounce />
        <Tooltip title="Export" arrow>
          <CustomCSVDownload
            fetchData={fetchExportData}
            automationId="export-roles"
            showDownloadIcon
            isLoading={false}
            filesNumber={1}
            isSecondary
            isLightButton
            hideText
            style={{ height: 36 }}
          />
          <span />
        </Tooltip>
        <Pagination
          currPageIdx={currentPage}
          isNextPageLoading={isNextPageLoading}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        <RoleActions />
      </div>
    </div>
  );
};

RolesHeader.propTypes = {
  currentPage: PropTypes.number.isRequired,
  isNextPageLoading: PropTypes.bool.isRequired,
  pageChanged: PropTypes.func.isRequired,
};

export default RolesHeader;
