import { API } from 'shared/utils/apiMiddleware';
import { CLOUD_TYPE_IDS } from '../constants/usersConstants';

export const signIn = async (username, password) =>
  API.post('billings', `/api/v1/users/signin`, { body: { username, password } });
export const renewToken = async (username, refreshToken) =>
  API.post('billings', `/api/v1/users/renewToken`, { body: { username, refreshToken } });

export const getSsoClientId = async (username, realmId) => {
  try {
    const clientId = username.toLowerCase().includes(realmId)
      ? null
      : await API.post('billings', '/api/v1/users/sso', { body: { username } });
    return clientId;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error in getSsoClientId: ${error}`);
    return null;
  }
};

export const signOut = async () => API.post('billings', `/api/v1/users/signout`);

export const getUserToken = async () => {
  const apiQuery = `/api/v1/users/token`;
  return API.get('billings', apiQuery);
};

export const forgotPassword = async (username) => {
  await API.post('billings', `/api/v1/users/forgotPassword`, { body: { username } });
};
export const forgotPasswordSubmit = async (username, code, password) => {
  await API.post('billings', `/api/v1/users/forgotPasswordSubmit`, { body: { username, code, newPassword: password } });
};

export class UsersApi {
  registerUser = async (registerData) => {
    const { username, password, keycloakAuth } = registerData;
    await API.post('billings', `/api/v1/users/signup`, { body: { username, password, keycloakAuth } });
  };

  signIn = async (username, password) => {
    const userID = await API.post('billings', `/api/v1/users/signin`, { body: { username, password } });
    return userID;
  };

  updateAutoAssignAccountProperty = async (autoAssignLinkedAccounts) => {
    const url = '/api/v1/users/account-info/auto-assign-linked-accounts';
    const data = await API.post('billings', url, { body: { autoAssignLinkedAccounts } });
    return data;
  };

  disableAutoCreationOfCustomers = async () => {
    const url = '/api/v1/users/account-info/disable-auto-creation-customers';
    const data = await API.post('billings', url);
    return data;
  };

  updateFilterOutMicrosoft365AccountProperty = async (accountId, filterOutMicrosoft365) => {
    const url = '/api/v1/users/account-info/filter-out-microsoft-365';
    const data = await API.post('billings', url, { body: { accountId, filterOutMicrosoft365 } });
    return data;
  };

  updateResellerAccountConfig = async ({ accountType, autoAssignLinkedAccounts, excludedLinkedAccountMatch }) => {
    const url = '/api/v1/users/account-info/account-config';
    const data = await API.post('billings', url, {
      body: { accountType, autoAssignLinkedAccounts, excludedLinkedAccountMatch },
    });
    return data;
  };

  updateExcludeLinkedAccountMatch = async (excludeRule) => {
    const url = '/api/v1/users/account-info/exclude-linked-account-match';
    const data = await API.post('billings', url, { body: { excludeRule } });
    return data;
  };

  getCloudAccountData = async (accountId, cloudTypeId) => {
    const getUrl = `/api/v1/users/account-info?accountId=${accountId}&cloudTypeId=${cloudTypeId}`;
    const data = await API.get('billings', getUrl);
    return data;
  };
  deleteAccount = async (accountId, cloudTypeId) => {
    const putUrl = `/api/v1/users/account-delete?accountId=${accountId}&cloudTypeId=${cloudTypeId}`;
    const data = await API.put('billings', putUrl);
    return data;
  };
  createUser = async (newUserData, registerUserInfoParams) => {
    const body = { newPileusUserData: newUserData, registerUserInfoParams };
    let response = null;
    try {
      response = await API.post('billings', `/api/v1/users`, { body });
      if (response) {
        return response;
      }
    } catch (error) {
      response = error;
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return response;
    }
  };

  getUserWithPlainSubUsers = () => {
    const query = `/api/v1/users/plain-sub-users`;
    return API.get('billings', query);
  };

  getUser = () => {
    const query = `/api/v1/users`;
    return API.get('billings', query);
  };

  getUserData = () => {
    const query = '/api/v1/user-management/users/user-data';
    return API.get('billings', query);
  };

  getAccounts = () => {
    const query = '/api/v1/user-management/accounts';
    return API.get('billings', query);
  };

  getSubUserInfo = (userKey) => {
    const query = `/api/v1/users/sub-user/${userKey}`;
    return API.get('billings', query);
  };

  updateUserTypeAndAwsAccId = (newType, awsAccountID, isConnectionSuccess) => {
    const body = {
      newType,
      awsAccountID,
      isConnectionSuccess,
    };
    return API.put('billings', '/api/v1/users/update-user-type-id', { body });
  };

  // User key is passed in the body params, should not be removed
  updateUserData = async (setParams, setValues, whereParams, whereValues) => {
    const body = {
      setParams,
      setValues,
      whereParams,
      whereValues,
    };
    return API.put('billings', '/api/v1/users/update-user-data', { body });
  };
  updateUserGeneralData = async (params) => {
    const body = params;
    return API.put('billings', '/api/v1/users/user-general-data', { body });
  };
  updateUserRole = async (body) => {
    const creareUrl = `/api/v1/users/update-user-role`;
    return API.put('billings', creareUrl, { body });
  };
  updateUserAccount = async (accountId, accountKey, newAccountName, newRoleARN) => {
    const body = { editedAccountId: accountId, editedAccountKey: accountKey, newAccountName, newRoleARN };
    return API.put('billings', '/api/v1/users/update-account', { body });
  };

  getUserAccounts = async () => {
    const getUrl = '/api/v1/users/user-accounts';
    return API.get('billings', getUrl);
  };

  // notifications

  getUserNotificationSettings = async () => {
    const getUrl = `/api/v1/users/user-settings/notifications`;
    return API.get('billings', getUrl);
  };

  updateUserNotificationSettings = async (body) => {
    const updateUrl = `/api/v1/users/user-settings/notifications`;
    return API.put('billings', updateUrl, { body });
  };

  // linked accounts

  fetchUserLinkedAccounts = async (cloudId) => {
    if (cloudId === CLOUD_TYPE_IDS.MULTI) {
      return [];
    }
    const cloudIdToUrl = {
      0: '/aws',
      1: '/azure',
      2: '/gcp',
    };
    const getUrl = `/api/v1/users/linked-accounts${cloudIdToUrl[cloudId]}`;
    return API.get('billings', getUrl);
  };

  // External keys

  signinWithToken = async (selectedRole) => {
    const apiQuery = `/api/v1/users/signin-with-token`;
    const body = { selectedRole };
    return API.post('billings', apiQuery, { body });
  };

  setExternalUserNameToUserKey = async (externalUserName, userKey) => {
    const putUrl = `/api/v1/users/user-key-from-external`;
    const body = {
      externalUserName,
      userKey,
    };
    return API.put('billings', putUrl, { body });
  };

  getUserKeyFromUserName = async (userName) => {
    const apiQuery = '/api/v1/users/user-key-from-name';
    return API.post('billings', apiQuery, { body: { userName } });
  };

  updateLinkedAccounts = async (linkedAccounts) => {
    const body = { linkedAccounts };
    const getUrl = `/api/v1/users/linked-accounts`;
    return API.put('billings', getUrl, { body });
  };

  // Sub Users

  getSubUsers = async () => {
    const getUrl = `/api/v1/users/subUsers`;
    return API.get('billings', getUrl);
  };

  deleteSubUser = async (userKey, accountKey) => {
    const body = { userKey, accountKey };
    const deleteUrl = `/api/v1/users/subUsers`;
    return API.del('billings', deleteUrl, { body });
  };
  deleteSubUsersBulk = async (data) => {
    const body = { users: data };
    const deleteUrl = `/api/v1/users/subUsers/bulk`;
    return API.del('billings', deleteUrl, { body });
  };
  createNewUsersInRole = async (body) => {
    const creareUrl = `/api/v1/users/new-user-with-role`;
    return API.post('billings', creareUrl, { body });
  };
  createNewCustomerSubUser = async (body) => {
    const creareUrl = `/api/v1/users/customer-sub-users`;
    return API.post('billings', creareUrl, { body });
  };
  createSubUserDashbaord = async (body) => {
    const postUrl = `/api/v1/users/create-dashbaord`;
    return API.post('billings', postUrl, { body });
  };

  getAvailableLinkedAccounts = async () => {
    const getUrl = `/api/v1/users/subUsers/availble-accounts`;
    return API.get('billings', getUrl);
  };
  getAvailableDivisionLinkedAccounts = async () => {
    const getUrl = `/api/v1/users/subUsers/availble-accounts-division`;
    return API.get('billings', getUrl);
  };
  migrateAzureEAToMCAAccount = async (billingExportConfiguration) => {
    const url = `/api/v1/users/on-boarding/migrate-azure-ea-to-mca-account`;
    return API.post('billings', url, { body: billingExportConfiguration });
  };
  saveAzureOnBoardingAppRegistrationConfiguration = async (appConfiguration) => {
    const body = { ...appConfiguration };
    const putUrl = `/api/v1/users/azure/reg-app`;
    return API.post('billings', putUrl, { body });
  };
  deleteAppRegistrationConfiguration = async (data) => {
    const body = data;
    const delUrl = `/api/v1/users/azure/del/reg-app?accountId=${data.accountId}&id=${data.id}`;
    return API.del('billings', delUrl, { body });
  };
  updateAppName = async (data) => {
    const body = data;
    const putUrl = `/api/v1/users/azure/update/reg-app`;
    return API.put('billings', putUrl, { body });
  };
  getAzureRegisteredApps = async () => {
    const getUrl = `/api/v1/users/azure/reg-app`;
    return API.get('billings', getUrl);
  };

  updateDefaultAccountId = async (accountId) => {
    const query = `/api/v1/users/default-account?accountId=${accountId}`;
    const result = await API.post('billings', query);
    return result;
  };

  getUserDataByAccount = async (accountId) => {
    const result = await API.get('billings', `/api/v1/users/byAccount?accountId=${accountId}`);
    return result;
  };
  getIsUserSubscribedToMondayWebhook = async () => {
    const result = await API.get('billings', '/api/v1/integrations/monday/is-role-id-subscribed');
    return result;
  };
}
