import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from './Button';

import styles from './pagination.module.scss';

interface PaginationProps {
  /** The current page index (first page is 0) */
  currPageIdx: number;
  /** The function to call when changing pages */
  onPageChange: (page: number) => void;
  /** The total number of pages */
  totalPages?: number | null;
  /** Indicates if the next page is currently loading (used for cursor-based pagination) */
  isNextPageLoading?: boolean;
}

/**
 * Pagination component for navigating through pages.
 *
 * For cursor-based pagination (DynamoDB): provide `totalPages` only after retrieving all pages and `isNextPageLoading`
 * should be provided to disable the next page button indicate if the next page is loading.
 */
const Pagination: React.FC<PaginationProps> = ({
  currPageIdx,
  onPageChange,
  totalPages = null,
  isNextPageLoading = false,
}) => (
  <div className={styles.pagerWrapper}>
    <Button
      onClick={() => onPageChange(currPageIdx - 1)}
      icon={() => <GenerateIcon iconName={ICONS.chevronLeft.name} />}
      automation-id="previous-page"
      className={styles}
      disabled={currPageIdx === 0}
      isTextButton
    />
    <span className={styles.pager}>
      Page {currPageIdx + 1} {totalPages ? `of ${totalPages}` : ''}
    </span>
    <Button
      onClick={() => onPageChange(currPageIdx + 1)}
      icon={() => <GenerateIcon iconName={ICONS.chevronRight.name} />}
      automation-id="next-page"
      className={styles}
      isLoading={isNextPageLoading}
      isTextButton
      disabled={totalPages != null && currPageIdx + 1 >= totalPages}
      overrideStyles={{
        borderRadius: '0 6px 6px 0',
      }}
    />
  </div>
);

export default Pagination;
