import React from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { getFakeT } from 'shared/utils/translationUtil';
import styles from './trial.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const textMap = {
  HEADER1: 'What you will gain:',
  ROWS1: [
    'A complete breakdown of your cloud spending, enabling you the visibility of where your money is spent',
    'Practical recommendations to lower the cost and reduce the waste',
    'Budget monitoring and control, tag management',
    'Access to our support team, and the ability to open support tickets to help you along the way',
  ],
  HEADER2: 'What do you need to do:',
  ROW2: 'Contact your account manager, technical specialist or our support',
  ROW3: (email) => <>at {email} and we will set it up for you.</>,
};

const texts = getFakeT(textMap);

const ExplanationList = () => {
  const brand = useBrand();
  const rows = texts('ROWS1');
  return (
    <div className={styles.explanationList}>
      <h4 className="payment__explenation-header">{texts('HEADER1')}</h4>
      <ul>
        {rows.map((rowText) => (
          <li key={rowText.length}>
            <GenerateIcon iconName={ICONS.circleCheckSolid.name} /> {rowText}
          </li>
        ))}
      </ul>
      <h4 className="payment__explenation-header">{texts('HEADER2')}</h4>
      <div>{texts('ROW2')}</div>
      <div>{texts('ROW3', <a href={`mailto:${brand.suppotEmail}`}>{brand.suppotEmail}</a>)}</div>
    </div>
  );
};
export default ExplanationList;
