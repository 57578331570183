import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { BrandTypes } from './enums';
import { Brand } from './types';
import brandHelper from './helper';
const { getBrandByDomain } = brandHelper;

const brandByDomain: Brand = getBrandByDomain();

const BrandContext = React.createContext<Brand>(brandByDomain);

export const useBrand = (): Brand => useContext(BrandContext);

export function withBrandContextProvider<P extends object>(Component: React.ComponentType<P>, rootStore: any) {
  function BrandContextProvider(props: Readonly<P>) {
    const isLoginPage = window.location.pathname.includes('/log_in') || window.location.pathname.includes('/register');

    const useRebranding = checkFeatureFlag(rootStore.usersStore, 'useRebranding');

    const brandValue: Brand = React.useMemo(() => {
      if (isLoginPage) {
        return brandByDomain;
      }

      if (useRebranding) {
        return getBrandByDomain(BrandTypes.Umbrella);
      }

      return getBrandByDomain(BrandTypes.Anodot);
    }, [isLoginPage, useRebranding]);

    return (
      <BrandContext.Provider value={brandValue}>
        <Component {...props} />
      </BrandContext.Provider>
    );
  }

  return observer(BrandContextProvider);
}

export function withBrandContextConsumer<P extends object>(Component: React.ComponentType<P & Brand>) {
  return function BrandContextConsumer(props: P) {
    return (
      <BrandContext.Consumer>{(brandData: Brand) => <Component {...brandData} {...props} />}</BrandContext.Consumer>
    );
  };
}
