import { BrandConfiguration } from './configuration';
import cloneDeep from 'lodash/cloneDeep';
import { BrandTypes } from './enums';
import { Brand } from './types';

class BrandHelper {
  private readonly brandsByDomain: Record<string, Brand> = cloneDeep(BrandConfiguration);

  getDomainKey = (): BrandTypes => {
    const url = window.location.hostname;
    switch (true) {
      case url.includes(BrandTypes.Umbrella): {
        return BrandTypes.Umbrella;
      }
      case url.includes(BrandTypes.Anodot):
      default: {
        return BrandTypes.Anodot;
      }
    }
  };

  getBrandByDomain = (key?: string): Brand => {
    const domainKey = key ?? this.getDomainKey();
    return this.brandsByDomain[domainKey];
  };
}

const brandHelper = new BrandHelper();
export default brandHelper;
