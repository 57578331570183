import React from 'react';
import classNames from 'classnames';
import IconFromPng from 'shared/components/IconFromPng';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner';
import { palette } from 'shared/constants/colorsConstants';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import styles from './Button.module.scss';

interface ButtonProps {
  text?: string;
  children?: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event?: React.KeyboardEvent<HTMLButtonElement>) => void;
  isTextButton?: boolean;
  isSecondary?: boolean;
  isSecondaryWithHover?: boolean;
  isError?: boolean;
  isErrorOutlined?: boolean;
  isGhost?: boolean;
  isLoading?: boolean;
  isGreen?: boolean;
  icon?: string | React.ElementType | (() => React.ReactElement);
  iconSize?: number;
  disabled?: boolean;
  variant?: string;
  automationId?: string;
  type?: 'submit' | 'button' | 'reset';
  name?: string | null;
  overrideStyles?: React.CSSProperties;
  iconPlacement?: 'left' | 'right';
  className?:
    | {
        button?: string;
        icon?: string;
        buttonContent?: string;
        buttonText?: string;
      }
    | string;
  isLightButton?: boolean;
  id?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  isTextButton = false,
  isSecondary = false,
  isSecondaryWithHover = false,
  isError = false,
  isErrorOutlined = false,
  isGhost = false,
  isLoading = false,
  isGreen = false,
  icon = null,
  iconSize = 12,
  disabled = false,
  automationId = '',
  type = 'submit',
  name = null,
  overrideStyles = {},
  iconPlacement = 'left',
  className = {},
  isLightButton = false,
  id,
}) => {
  const handleClick = (val: React.MouseEvent<HTMLButtonElement>) => {
    segmentEvent({
      target: automationId || text || icon,
      automationId,
    });
    if (onClick) {
      onClick(val);
    }
  };

  const renderIcon = () =>
    icon ? (
      <div className={styles.iconWrapper} data-icon-wrapper={true}>
        <IconFromPng size={`${iconSize}px`} icon={icon} />
      </div>
    ) : null;

  return (
    <button
      id={id}
      className={classNames(
        typeof className === 'object' ? className.button : className,
        typeof className === 'object' ? className.icon : undefined,
        styles.andtButton,
        { [styles.ghost]: isGhost },
        { [styles.green]: isGreen },
        { [styles.textButton]: isTextButton },
        { [styles.secondary]: isSecondary || isSecondaryWithHover },
        { [styles.secondaryHover]: isSecondaryWithHover },
        { [styles.error]: isError },
        { [styles.errorOutlined]: isErrorOutlined },
      )}
      name={name || undefined}
      style={{ ...overrideStyles }}
      onClick={handleClick}
      disabled={disabled || isLoading}
      type={type}
      automation-id={automationId}
    >
      <div
        className={classNames(
          styles.buttonContent,
          isLoading ? styles.loading : '',
          typeof className === 'object' ? className.buttonContent : undefined,
        )}
      >
        {isLoading && (
          <div className={styles.spinnerWrapper}>
            <Spinner
              size={SPINNER_SIZES.X_SMALL}
              color={isGhost || isTextButton || isLightButton ? palette.blue[500] : palette.white[500]}
            />
          </div>
        )}
        {iconPlacement === 'left' && renderIcon()}
        {text ? (
          <span
            className={classNames(styles.buttonText, typeof className === 'object' ? className.buttonText : undefined)}
          >
            {text}
          </span>
        ) : null}
        {iconPlacement === 'right' && renderIcon()}
      </div>
    </button>
  );
};

export default Button;
