import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { ClickAwayListener } from '@mui/material';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import Button from './Button';
import styles from './ButtonDropdown.module.scss';
import Tooltip from './Tooltip';

interface ButtonDropdownProps {
  className?: string;
  content?: React.ReactNode;
  text?: string;
  tooltipText?: string | null;
  closeOnSelect?: boolean;
  disabled?: boolean;
  onClose?: () => void;
  arrow?: boolean;
  isSecondary?: boolean;
  isTextButton?: boolean;
  overrideMenuStyles?: React.CSSProperties | null;
  overrideButtonStyles?: React.CSSProperties | null;
  menuOpen?: boolean | null;
  setMenuOpen?: ((open: boolean) => void) | null;
  isLoading?: boolean;
  automationId?: string;
  iconPlacement?: 'left' | 'right';
  children: React.ReactElement | React.ReactElement[];
  icon?: string | React.ElementType | (() => React.ReactElement) | null;
}

const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  text = '',
  tooltipText = null,
  closeOnSelect = true,
  disabled = false,
  onClose = () => {},
  arrow = false,
  isSecondary = true,
  isTextButton = false,
  overrideMenuStyles = null,
  overrideButtonStyles = null,
  menuOpen: controlledMenuOpen = null,
  setMenuOpen: controlledSetMenuOpen = null,
  isLoading = false,
  automationId = '',
  iconPlacement = 'left',
  children,
  icon = null,
}) => {
  const [uncontrolledMenuOpen, uncontrolledSetMenuOpen] = useState<boolean>(false);
  const menuOpen = controlledMenuOpen !== null ? controlledMenuOpen : uncontrolledMenuOpen;
  const setMenuOpen = controlledSetMenuOpen !== null ? controlledSetMenuOpen : uncontrolledSetMenuOpen;

  const handleSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    let currentElement = event.target as HTMLElement;
    let option = '';
    for (let i = 0; i < 10; i++) {
      if (currentElement.id) {
        option = currentElement.id;
        break;
      }
      currentElement = currentElement.parentElement as HTMLElement;
    }
    segmentEvent({
      type: 'select',
      target: automationId || (typeof text === 'string' && text) || 'ButtonDropdown',
      data: {
        option,
      },
    });
    setMenuOpen(false);
  };

  useEffect(() => {
    if (!menuOpen) {
      onClose();
    }
  }, [onClose, menuOpen]);

  const button = (
    <div className={classnames({ [styles.withArrow]: arrow })}>
      <Button
        text={text}
        disabled={disabled}
        icon={icon || undefined}
        iconPlacement={iconPlacement}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
          setMenuOpen(!menuOpen);
        }}
        overrideStyles={{ paddingLeft: 8, paddingRight: 8, ...overrideButtonStyles }}
        isSecondary={isSecondary}
        isTextButton={isTextButton}
        iconSize={16}
        isLoading={isLoading}
        automationId={automationId}
      />
    </div>
  );

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setMenuOpen(false)}>
      <div className={styles.container}>
        {tooltipText && !menuOpen && !disabled ? (
          <Tooltip title={tooltipText} arrow>
            {button}
          </Tooltip>
        ) : (
          button
        )}
        {menuOpen && (
          <div
            onClick={(e) => closeOnSelect && handleSelect(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                closeOnSelect && handleSelect(e as unknown as React.MouseEvent<HTMLDivElement>);
              }
            }}
            style={overrideMenuStyles || undefined}
            className={styles.buttonDropDown}
            role="button"
            tabIndex={0}
          >
            {children}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ButtonDropdown;
