import React from 'react';
import CarbonEmissionGif from './assets/carbon-emission.gif';
import styles from './EnableCarbonEmission.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const EnableCarbonEmission = () => {
  const brand = useBrand();
  return (
    <div className={styles.enableCarbonEmissionContainer}>
      <img src={CarbonEmissionGif} className={styles.carbonEmissionGif} alt="" />
      <h4>Want to track your GreenOps carbon emissions? 🚀</h4>
      <p>
        Reach out to our <a href={`mailto:${brand.suppotEmail}`}>support team</a> to get you started!
      </p>
    </div>
  );
};

export default EnableCarbonEmission;
